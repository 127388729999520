import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextBaseLayout } from "./components/baseLayout";

import Login from './pages/login';
import ForgotPassword from "./pages/forgotPassword";
import ResetPassword from './pages/resetPassword';
import Dashboard from './pages/dashboard';
import Merchants from './pages/merchantList';
import AdminUsers from './pages/adminUsers';
import LogoutComp from './pages/logout';
import Profile from './pages/profile';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword/:code" element={<ResetPassword />} />
                

                <Route path="/" element={<ContextBaseLayout />}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="merchants" element={<Merchants />} />
                    <Route path="admins" element={<AdminUsers />} />
                    <Route path="profile" element={<Profile />} />
                    
                    <Route path="logout" element={<LogoutComp />} />
                </Route>

            </Routes>
        </BrowserRouter>
    );
}

export default App;
