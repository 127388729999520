const themeColorPrimary = "#333333";
const themeColorPrimaryHover = "#545454";
const logoColorSecondary = "#23236D";
const paragraphColor = "#282E38";
const headingColor = "#282E38";
const linkTextColor = "#282E38";

const inputBorderColor = "#EBEBEB";
const inputBgColor = "#FFFFFF";
const inputTextColor = "#282E38";

const buttonTextColor = "#FFFFFF"


const Colors = {
    //Common Colors
    themeColorPrimary: themeColorPrimary,
    themeColorPrimaryHover: themeColorPrimaryHover,
    logoColorSecondary: logoColorSecondary,
    headingColor: headingColor,
    linkTextColor: linkTextColor,
    paragraphColor: paragraphColor,
    inputBorderColor: inputBorderColor,
    inputBgColor: inputBgColor,
    inputTextColor: inputTextColor,

    buttonBgColor: themeColorPrimary,
    buttonHoverBgColor: themeColorPrimaryHover,
    buttonTextColor: buttonTextColor,

    greyColor: "#555555", //TODO - cannot use like this, need to change this
    whiteColor: "#FFFFFF", //TODO - cannot use like this, need to change this
    blackColor: "#000000", //TODO - cannot use like this, need to change this
    commonBorderColor: "#DDDDDD", //TODO - cannot use like this, need to change this

    //MAIN SIDEBAR COLORS
    mainSidebarBorderColor: "#EBEBEB",
    mainSidebarLogoBottomBorderColor: "#EBEBEB",
    mainSidebarIconColor: "#666666",
    mainSidebarTextColor: "#666666",
    notificationBadgeBgColor: "#666666",
    notificationBadgeTextColor: "#FFFFFF",
    accordionHeaderTextColor: "#555555",
    newFolderButtonBgColor: "#F5F5F5",
    newFolderButtonBgHoverColor: "#eaeaea",
    newFolderButtonTextColor: "#555555",
    folderFirstLetterBgColor: "#555555",

    //ALL TABLE COLORS
    tableHeaderColor: "#F8F9FB",
    tableBorderColor: "#EBEBEB",

    //MAIN CONTENT COLORS
    MainContentBgColor: "#FFFFFF",
    BuiderMainContentBgColor: "#F5F5F5",

    profileIconGreyColor: "#d5d6d7",
    topbarDropdownGreyColor: "#f3f3f3",
    bottombarActiveColor: "#39397e",

    formbuilderWrapperFieldBorder: "#DDDDDD",
    formbuilderActionItemColor: "#777777",
    formbuilderTabTextColor: "#999999",
    formbuilderMasterFieldItemBorder: "#EEEEEE",
    formbuilderMasterFieldIconColor: "#555555",
    inputDisabledBgColor: "#eeeeee",
    hiddenSpanBgColor: "#ddd",

    //TABLE DROPDOWN
    tableDropdownTopBorder: "#eaeaea",
    tableDropdownDeleteTextColor: "#FF0000",

    //DROPDOWN COLORS REACT-SELECT
    dropdownBtnBg: "#FFFFFF",
    dropdownBtnBgHover: "#F5F5F5",
    dropdownBtnBorderColor: "#EBEBEB",
    dropdownBtnTextColor: "#222222",

    dropdownBorderColor: "#eeeeee",
    dropdownLogicBorderColor: "#dddddd",
    dropdownBoxShadowColor: "0px 0px 10px 1px #EEEEEE",
    
    dropdownItemBg: "#FFFFFF",
    dropdownItemBgHover: "#FAFAFA",
    dropdownItemBgSelected: "#F5F5F5",
    dropdownItemTextColor: "#222222",

    //DIALOG DROPDOWNS
    dropdownDialogBtnBg: "#FFFFFF",
    dropdownDialogBtnBgHover: "#F5F5F5",
    dropdownDialogBtnBorderColor: "#D0D0D0",
    dropdownDialogBtnTextColor: "#222222",

    dropdownDialogBorderColor: "#eeeeee",
    dropdownDialogLogicBorderColor: "#dddddd",
    dropdownDialogBoxShadowColor: "0px 0px 10px 1px #EEEEEE",

    dropdownDialogItemBg: "#FFFFFF",
    dropdownDialogItemBgHover: "#FAFAFA",
    dropdownDialogItemBgSelected: "#F5F5F5",
    dropdownDialogItemTextColor: "#222222",

    //TABLE SEARCH 
    searchBackgroundColor: "#FFFFFF",
    searchInputBorderColor: "#EBEBEB",

    //Modal element colors
    modalCancelBtnBgColor: "#CCCCCC",
    modalCancelBtnBgHover: "#DDDDDD",
    modalSubmitBtnBgColor: themeColorPrimary,
    modalSubmitBtnBgHover: themeColorPrimaryHover,
    modalInputBorderColor: "#D0D0D0",

    //Pagination
    paginationSelectedColor: themeColorPrimary,
    paginationBorderColor: "#D0D0D0",
    paginationDisabledColor: "#fafafa",
    paginationHoverColor: themeColorPrimaryHover + "11",
    paginationTextColor: "#333333",

    //Alert Dialog Colors
    alertDialogCancelButtonColor: "#595959",
    alertDialogSubmitButtonColor: "#FF5500",

    statusSpanActiveBackgroundColor:"#61b33bcc",
    statusSpanActiveTextColor:"#fff",
    statusSpanInActiveBackgroundColor:"#d61a2ddd",
    statusSpanInActiveTextColor:"#fff",
    statusSpanDefaultBackgroundColor:"#f7ce14",
    statusSpanDefaultTextColor:"#fff",

    // /ADD/EDIT FORM HEADER
    backButtonIconColor: themeColorPrimary,
    backButtonHoverBg: themeColorPrimary + "11",

}
export { Colors };