import Dropdown from 'react-bootstrap/Dropdown';
import { FaRegUser } from "react-icons/fa6";
import styled from 'styled-components';
import {Colors} from '../styles/colors';

const DropdownWrap = styled.div`
    .dropdown .btn:hover{
      background:none ;
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
      background:none ;
    }
    button{
      display:flex;
      align-items:center;
      background:none;
      border:none;
      padding:0;
      
      span{
        color:${Colors.paragraphColor};
        margin-left:5px;
        font-size:13px;
        margin-bottom:0;
      }
    }
    .dropdown-toggle::after{
      content:none;
    }

    .dropdown-menu.show{
      padding:0;
    }
    .dropdown-item{
      padding:10px 20px;
      font-size:13px;
      display:flex;
      align-items:center;
      svg{
        font-size:20px;
        margin-right:5px;
        color:${Colors.paragraphColor};
      }
    }
`;
const UserWrap = styled.div`
    background:${Colors.profileIconGreyColor};
    width:30px;
    height:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:100%;

    svg{
      color:${Colors.themeColorPrimary};
      font-size:16px;
    }
`
const ProfileDropdown = (props)=> {
    return (
      <DropdownWrap>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">
          <UserWrap>
              <FaRegUser/>
          </UserWrap>
          <span>{props.dropdownLabel}</span>
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            {
              (props.dropdownList) ?
              props.dropdownList.map((item)=>{
                return(
                  <Dropdown.Item href={item.optionUrl}>{item.optionIcon}{item.option}</Dropdown.Item>
                )
              })
              :
              "Account Deactivated"
            }
          </Dropdown.Menu>
        </Dropdown>
      </DropdownWrap>
    );
  }
  
  export default ProfileDropdown;