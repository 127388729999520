import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const LogoutComp = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    
    const logoutAndRedirect = () => {
        logout();
        navigate("/");
    }
    return <>
        {
            logoutAndRedirect()
        }
    </>
}


export default LogoutComp;