import styled from 'styled-components';
import {Colors} from '../styles/colors';

export const PaginiationWrapper = styled.div`
    margin-top:20px;
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
    }

    li{
        padding:0px;
        background:#fff;
        display:flex;
        align-items:center;
        justify-content:center;
        width:35px;
        height:35px;
        border:1px solid ${Colors.paginationBorderColor};

        &:hover{
            background-color:${Colors.paginationHoverColor};
            border:1px solid ${Colors.paginationHoverColor};
        }

        &:hover a{
            // color:${Colors.whiteColor} !important;
        }
    }

    li a{
        width:35px;
        height:35px;
        text-decoration:none;
        display:flex;
        align-items:center;
        justify-content:center;
        padding: 0;
        color:${Colors.paginationTextColor};

        &:hover{
            // color:${Colors.whiteColor} !important;
        }
    }

    li.previous, li.next{
        padding: 5px 5px;
    }
    li.previous{
        border-top-left-radius:6px;
        border-bottom-left-radius:6px;
    }
    li.next{
        border-top-right-radius:6px;
        border-bottom-right-radius:6px;
    }

    li.previous.disabled, li.next.disabled{
        background-color:${Colors.paginationDisabledColor};
        cursor:not-allowed;
        a{
            cursor:not-allowed;
        }
        svg{
            cursor:not-allowed;
        }

        &:hover a{
            color:${Colors.themeColorPrimaryHover} !important;
        }
        &:hover svg{
            color:${Colors.themeColorPrimaryHover} !important;
        }
    }

    li.selected{
        background-color:${Colors.paginationSelectedColor};
        color:${Colors.whiteColor};
        border:1px solid ${Colors.paginationSelectedColor};
    }

    svg{
        font-size:20px;
    }
`;


export const PaginiationSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    p{
        margin-bottom: 0;
        font-size: 16px;
    }
`;