import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();

    if (!user) {
        // user is not authenticated
        return <Navigate to="/" />;
    }
    return children;
};