import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { CHECK_FORGOT_PASSWORD_CODE, RESET_PASSWORD } from '../../utils/apiEndPoints';
import { callApi, isEmptyVariable } from '../../utils/utilFunctions';
import { useNavigate, Link, useParams } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import {ResetpassWrapper, ResetPassCol, ResetpassSection} from './resetpassword.style';
import GlobalStyle from '../../components/styles/global-styles'
import LoginHeader from '../../components/loginHeader'
import { Container, Row, Col } from 'react-bootstrap';

const INITIAL_STATE = {
    codeVerifiedStatus: "C",
    errors: {},
    isLoading: false,
    apiResponse: "",
    password:"",
    confirmPassword:"",
}

export default function ResetPassword() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const params = useParams();
    const [ state, setState ] = useState({ ...INITIAL_STATE })

    useEffect(() => {
        if (user && user !== null) {
            navigate("/dashboard");
        }else{
            //Check forgot password code from URL
            checkForgotPasswordCode();
        }
    }, []);

    const validate = () => {
        let hasErrors = false;
        let errors = {}

        if (isEmptyVariable(state.password)) {
            hasErrors = true;
            errors.passwordErr = "Please enter the password"
        }

        if (isEmptyVariable(state.confirmPassword)) {
            hasErrors = true;
            errors.confirmPasswordErr = "Please confirm the password"
        }

        if(!isEmptyVariable(state.password) && !isEmptyVariable(state.password)
        && state.password != state.confirmPassword){
            hasErrors = true;
            errors.confirmPasswordErr = "Password mismatch"
        }

        if (hasErrors) {
            setState({
                ...state,
                errors: { ...errors }
            })
        }

        return hasErrors;
    }

    const checkForgotPasswordCode = async () => {
        let response = await callApi(CHECK_FORGOT_PASSWORD_CODE, {
            code: params.code,
        });

        if (response.data.responseCode === 0) {
            setState({
                ...state,
                codeVerifiedStatus:"Y"
            })
        }else{
            setState({
                ...state,
                codeVerifiedStatus:"N"
            })
        }
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onResetPasswordSubmit();
        }
    }

    const onResetPasswordSubmit = async () => {
        if (!validate()) {
            setState({
                ...state,
                isLoading: true,
                apiResponse: "",
            })

            let response = await callApi(RESET_PASSWORD, {
                password: state.password,
                confirmPassword: state.confirmPassword,
                code:params.code
            });

            setState({
                ...state,
                isLoading: false
            })

            if (response.data.responseCode === 0) {
                setState({
                    ...state,
                    password:"",
                    confirmPassword:"",
                    apiResponse: response.data.responseMessage
                })
            } else {
                setState({
                    ...state,
                    apiResponse: "",
                    errors: { apiErr: response.data.responseMessage }
                })

                //clear the validation errros
                setTimeout(() => {
                    setState({
                        ...state,
                        errors: {}
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            //clear the validation errros
            setTimeout(() => {
                setState({
                    ...state,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    const onInputChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    return (
        <ResetpassSection>
            <LoginHeader/>
            <ResetpassWrapper>
            <GlobalStyle/>
                <Container>
                    <Row>
                        <Col md={12}>
                            <ResetPassCol>
                                {
                                state.codeVerifiedStatus == "Y" &&
                                <div>
                                    <h1>Reset Password</h1>
                                    <input
                                        type="password"
                                        placeholder='Password'
                                        value={state.password}
                                        name={"password"}
                                        onChange={e => onInputChange(e)}
                                    />
                                    {
                                        !isEmptyVariable(state.errors.passwordErr) &&
                                        <span className="err-span">{state.errors.passwordErr}</span>
                                    }

                                    <input
                                        type="password"
                                        placeholder='Confirm Password'
                                        value={state.confirmPassword}
                                        name={"confirmPassword"}
                                        onChange={e => onInputChange(e)}
                                        onKeyDown={(e) => handleEnterKeyPress(e)}
                                    />
                                    {
                                        !isEmptyVariable(state.errors.confirmPasswordErr) &&
                                        <span className="err-span">{state.errors.confirmPasswordErr}</span>
                                    }

                                    <button onClick={onResetPasswordSubmit} style={{ display: "flex" }} disabled={state.isLoading}>
                                        Submit
                                        {
                                            state.isLoading &&
                                            <ThreeDots
                                                height="40"
                                                width="40"
                                                radius="9"
                                                color="#4fa94d"
                                                ariaLabel="three-dots-loading"
                                                wrapperStyle={{}}
                                                wrapperClassName=""
                                                visible={true}
                                            />
                                        }
                                    </button>
                                    {
                                        !isEmptyVariable(state.errors.apiErr) &&
                                        <span className="err-span">{state.errors.apiErr}</span>
                                    }
                                    {
                                        !isEmptyVariable(state.apiResponse) &&
                                        <span className="api-resp">{state.apiResponse}</span>
                                    }
                                    <Link className="goto-login-btn" to={"/"}>Go to Login</Link>
                                </div>
                            }
                            {
                                state.codeVerifiedStatus == "N" &&
                                <div>
                                    INVALID CODE
                                </div>
                            }
                            {
                                state.codeVerifiedStatus == "C" &&
                                <div>
                                    Validating the URL
                                </div>
                            }
                            </ResetPassCol>
                        </Col>
                    </Row>
                </Container>
            </ResetpassWrapper>
        </ResetpassSection>
    )
}