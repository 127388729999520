import styled from 'styled-components';
import { device } from '../../components/styles/device';
import { Colors } from '../../components/styles/colors';
import { Others } from '../../components/styles/others';


export const ResetpassSection = styled.section`
    background-image: url('./images/bg-image.jpg');
    background-repeat:no-repeat;
`;

export const ResetpassWrapper = styled.section`
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh - 90px);
`;

export const ResetPassCol = styled.div`
    max-width:450px;
    margin:auto;
    text-align:center;

    h1{
        font-size:22px;
    }

    input{
        width:100%;
        margin-bottom:15px;
        padding:10px 15px;
        border-radius:0;
        background:#fff;
        border-radius: 4px;
    }

    input[type="email"]{
        
    }

    input[type="password"]{

    }

    button{
        border:none;
        background-color:${Colors.themeColorPrimary};
        width:100%;
        padding:10px 15px;
        text-align:center;
        justify-content: center;
        color:#fff;
        text-transform:uppercase;
        margin-bottom:15px;
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
        }

        svg{
            margin-left:10px;
        }
    }

    .api-resp{
        display:block;
        margin-top:-10px;
        text-align:left;
    }

    .goto-login-btn{
        display: inline-block;
        margin-bottom: 20px;
        text-decoration:underline;
    }

`;