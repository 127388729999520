import Dropdown from 'react-bootstrap/Dropdown';
import { isEmptyArray, isEmptyVariable } from '../../utils/utilFunctions';
import { Colors } from '../styles/colors';
import styled from 'styled-components';
//write internal style only

const DropdownWrapper = styled.div`
    display:flex;
    flex-direction:column;

    span{
        font-size:13px;
        margin-bottom:0;
    }
    .dropdown .dropdown-toggle.btn{
        background:${Colors.dropdownBtnBg};
        width:100%;
        border:none;
        padding:3px 10px;
        font-size:13px;
        color:${Colors.dropdownBtnTextColor};
        border:none;
        height:34px;
        border-radius:4px;
        border:1px solid ${Colors.dropdownBtnBorderColor};
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .dropdown .dropdown-toggle.btn:disabled{
        background:${Colors.inputDisabledBgColor};
    }
    .dropdown .dropdown-toggle.btn.show{
        background:${Colors.dropdownBtnBgHover};
    }
    .dropdown .dropdown-toggle.btn:hover{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .dropdown-menu.show{
        padding:0;
        min-width:${props => props.minWidth ? props.minWidth : "200px"};
        border-radius:5px;
        border:1px solid ${Colors.dropdownBorderColor};
        box-shadow:${Colors.dropdownBoxShadowColor};
        max-height: 220px;
        overflow: auto;
    }
    .dropdown-item{
        padding:8px 15px;
        font-size:13px;
        background:${Colors.dropdownItemBg};
        color:${Colors.dropdownItemTextColor};

        &:first-child{
            border-top-left-radius:5px;
            border-top-right-radius:5px;
        }
        &:last-child{
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
        }
    }
    .dropdown-item:hover{
        background:${Colors.dropdownItemBgHover} !important;
    }
`;

const BasicDropdown = (props) => {
    const { placeholder, selectedValue, label, options, dropdownItemClick, disabled,
        wrapperStyles, minWidth } = props;

    return (<DropdownWrapper style={{ ...wrapperStyles }} minWidth={minWidth}>
        <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" disabled={disabled}>
                {isEmptyVariable(selectedValue) ? placeholder : selectedValue}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    !isEmptyArray(options) &&
                    options.map((option) => (
                        <Dropdown.Item as="button" onClick={() => { dropdownItemClick(option) }}>
                            {option[label]}
                        </Dropdown.Item>
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    </DropdownWrapper>
    );
}

export default BasicDropdown;