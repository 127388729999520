import Modal from 'react-bootstrap/Modal';
import { isEmptyVariable } from '../../utils/utilFunctions';
import { BsExclamationCircle } from "react-icons/bs";
import styled from 'styled-components';

const AlertDialogWrapper = styled.div`

`;

const AlertDialog = (props) => {
    return <AlertDialogWrapper >
        <Modal show={props.alertState.showAlertDialog} onHide={props.alertState.handleAlertDialogClose} className="alert-dialog-modal">
        <Modal.Header closeButton  onClick={() => props.onClickCancel()}>
        </Modal.Header>
            <Modal.Body>
                <BsExclamationCircle/>
                {
                    !isEmptyVariable(props.alertState.heading) &&
                    <h3>{props.alertState.heading}</h3>
                }

                {
                    !isEmptyVariable(props.alertState.message) &&
                    <p>{props.alertState.message}</p>
                }

                {
                    !isEmptyVariable(props.alertState.cancelBtnLabel) &&
                    <button className="cancel-btn" onClick={() => props.onClickCancel()}>{props.alertState.cancelBtnLabel}</button>
                }
                {
                    !isEmptyVariable(props.alertState.okayBtnLabel) &&
                    <button onClick={() => props.onClickSubmit(props.alertState.operation,props.alertState.selectedItem)}>{props.alertState.okayBtnLabel}</button>
                }
            </Modal.Body>

        </Modal>

    </AlertDialogWrapper>
}

export default AlertDialog;