import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';

export const FormWrapper = styled.div`
    padding:20px;
    .ql-editor{
        height:170px;
        padding:8px;
    }

    .ql-toolbar.ql-snow{
        border-top-left-radius: 4px;    
        border-top-right-radius: 4px;    
        border: 1px solid ${Colors.modalInputBorderColor};

        span{
            margin-bottom:0;
        }
    }
    .ql-container.ql-snow{
        border-bottom-left-radius: 4px;    
        border-bottom-right-radius: 4px;   
        border: 1px solid ${Colors.modalInputBorderColor};
    }

    .ql-editor.ql-blank::before{
        font-size: 13px;
        color: #999999;
        font-family: 'Inter',sans-serif;
        left:8px;
        right:8px;
        font-style:unset;
    }
`;
export const ButtonDefault = styled.button`
    display:flex;
    background:${Colors.themeColorPrimary};
    border:none;
    color:${Colors.whiteColor};
    padding:5px 20px;
    border-radius:4px;
    font-size:13px;
    transition:all .5s;
    align-items:center;

    &:hover{
        background:${Colors.themeColorPrimaryHover};
        transition:all .5s;
    }

    svg{
        width:16px;
        flex-shrink:0;
        margin-right:5px;
    }
`;
export const ButtonSecondary = styled.button`
    padding: 3px 15px;
    font-size: 12px;
    margin:0;
    border-radius:4px;
    background:${Colors.themeColorPrimary};
    color:${Colors.whiteColor};
    border:none;
`;
export const ButtonIconLayout = styled.button`
    background:transparent;
    color: ${Colors.buttonIconColor};
    padding: 7px;
    border-radius: 4px;
    margin: ${props=>props.margin?props.margin:"0"};

    &:hover{
        background: ${Colors.buttonIconHoverBgColor};
    }

    svg{
        font-size: 20px;
    }
`;
export const FieldGroupWrapper = styled.div`
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    border-radius:4px;

    input{
        border: 1px solid ${Colors.modalInputBorderColor};
        border-radius:4px;
        width:100%;
        padding:8px;
        margin-bottom:15px;
        height:34px;
        box-sizing: border-box;
        font-size:13px;
    }

    .input-disabled{
        background: #fafafa;
    }

    textarea{
        font-size: 13px;
        border: 1px solid ${Colors.modalInputBorderColor};
        border-radius:4px;
        width:100%;
        padding:8px;
        margin-bottom:15px;
        resize: none;
        box-sizing: border-box;
        font-size:13px;
    }

    label{
        font-size:12px;
        line-height:1;
        display:block;
        margin-bottom:5px;
        font-weight:500;
    }

    h6{

    }
`;
export const FieldGroupAddImageWrapper = styled.div`
    padding: 20px;
    border: 1px solid #e1e1e1;
    margin-bottom: 20px;
    border-radius:4px;

    label{
        font-size:12px;
        line-height:1;
        display:block;
        margin-bottom:5px;
        font-weight:500;
    }

    h6{

    }
`;