import {useReducer} from 'react';

const initialState = {
    email:"",
    password:"",
    errors:{},
    isLoginLoading:false,
}

const loginReducer = (state,action) => {
    switch(action.type){
        case "SET_FIELDVALUE" : {
            return {
                ...state,
                [action.fieldName]:action.fieldValue
            }
        }
        case "SET_LOGIN_LOADER_FLAG" : {
            return {
                ...state,
                isLoginLoading:action.flag
            }
        }
        case "SET_VALIDATION_ERRORS" : {
            return {
                ...state,
                errors:{...action.errors}
            }
        }
        default:{

        }
    }
}

export const GetLoginReducer = () => {
    return useReducer(loginReducer, initialState);
};