import styled from 'styled-components';
import { device } from '../styles/device';
import { Colors } from '../styles/colors';
import { Others } from '../styles/others';

export const LoginHeaderWrap = styled.div`
    height:90px;
    display:flex;
    align-items:center;
`;

export const LoginHeaderCol = styled.div`
    padding:30px 50px;

    img{
        max-width:200px;
    }
`;