import React, { useEffect } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import {
    ProfileWrapper, ProfileDetailsCol, ProfilePicLayout, ProfilePicCol, ProfileDescLayout,
    DeleteLayout, PasswordWrapper, PasswordCol, PasswordFieldCol
} from "./index.style";
import { useAuth } from "../../hooks/useAuth";
import { useOutletContext } from "react-router-dom";
import { GetReducer } from "./reducer";
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from "../../utils/utilFunctions";
import { WARNING_MSG_TIMEOUT } from "../../utils/constants";
import { CHANGE_PASSWORD } from "../../utils/apiEndPoints";

const Profile = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [profileState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "Profile", heading: "Profile" })
    }, []);


    const validateChangePassword = () => {
        let hasErrors = false;
        let errors = {};

        if (isEmptyVariable(profileState.oldPassword)) {
            hasErrors = true;
            errors.oldPassword = "Please enter the old password"
        }

        if (isEmptyVariable(profileState.newPassword)) {
            hasErrors = true;
            errors.newPassword = "Please enter the new password"
        }

        if (isEmptyVariable(profileState.confirmNewPassword)) {
            hasErrors = true;
            errors.confirmNewPassword = "Please confirm the new password"
        }

        if (!isEmptyVariable(profileState.newPassword) && !isEmptyVariable(profileState.confirmNewPassword) &&
            profileState.newPassword !== profileState.confirmNewPassword) {
            hasErrors = true;
            errors.confirmNewPassword = "Password mismatch"
        }

        dispatch({
            type: "SET_VALIDATION_ERRORS",
            errors: errors
        })
        return hasErrors;
    }

    const changePassword = async () => {

        if (!validateChangePassword()) {
            try {
                await callAuthApi(CHANGE_PASSWORD, {
                    oldPassword: profileState.oldPassword,
                    newPassword: profileState.newPassword,
                }, user, logout);

                dispatch({
                    type:"RESET_CHANGE_PASSWORD_FIELDS",
                    response:"Password changed successfully"
                })

                setTimeout(() => {
                    dispatch({
                        type: "RESET_CHANGE_PASSWORD_FIELDS",
                        response: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            } catch (alertErr) {
                dispatch({
                    type: "SET_VALIDATION_ERRORS",
                    errors: {
                        apiErr: ifEmptyReturnStr(alertErr.message, alert.heading)
                    }
                })
            }
        } else {
            setTimeout(() => {
                dispatch({
                    type: "SET_VALIDATION_ERRORS",
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }


    return <ProfileWrapper>
        <h5>Profile</h5>
        <ProfileDetailsCol>

            <ProfilePicLayout>
                <ProfilePicCol>
                    <DeleteLayout className="delete-layout">
                        <FaRegTrashCan />
                    </DeleteLayout>
                    <img src="images/profile.jpg" alt="profile" />
                </ProfilePicCol>
                <button className="change-photo-btn">Change Photo</button>
            </ProfilePicLayout>

            <ProfileDescLayout>
                <h6>{user.firstName + " " + user.lastName}</h6>
                <h6>{user.email}</h6>
                <h6>{user.phoneNumber}</h6>
                <h6>{user.userType}</h6>
            </ProfileDescLayout>

        </ProfileDetailsCol>

        <PasswordWrapper>
            <PasswordCol>
                <h5>Change Password</h5>
                <PasswordFieldCol>
                    <label>Old Password</label>
                    <input
                        type="password"
                        name="oldPassword"
                        value={profileState.oldPassword}
                        onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "oldPassword", fieldValue: e.target.value })}
                    />
                </PasswordFieldCol>
                {
                    !isEmptyVariable(profileState.errors.oldPassword) &&
                    <span className="err-span m-b-10">{profileState.errors.oldPassword}</span>
                }
                <PasswordFieldCol>
                    <label>New Password</label>
                    <input
                        type="password"
                        name="newPassword"
                        value={profileState.newPassword}
                        onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "newPassword", fieldValue: e.target.value })}
                    />
                </PasswordFieldCol>
                {
                    !isEmptyVariable(profileState.errors.newPassword) &&
                    <span className="err-span m-b-10">{profileState.errors.newPassword}</span>
                }
                <PasswordFieldCol>
                    <label>Confirm Password</label>
                    <input
                        type="password"
                        name="confirmNewPassword"
                        value={profileState.confirmNewPassword}
                        onChange={e => dispatch({ type: "SET_FIELDVALUE", fieldName: "confirmNewPassword", fieldValue: e.target.value })}
                    />
                </PasswordFieldCol>
                {
                    !isEmptyVariable(profileState.errors.confirmNewPassword) &&
                    <span className="err-span m-b-10">{profileState.errors.confirmNewPassword}</span>
                }
                {
                    !isEmptyVariable(profileState.errors.apiErr) &&
                    <span className="err-span m-b-10">{profileState.errors.apiErr}</span>
                }
                {
                    !isEmptyVariable(profileState.apiResponse) &&
                    <span className="success-span m-b-10">{profileState.apiResponse}</span>
                }
                <button className="save-password-btn" onClick={changePassword}>Save</button>
            </PasswordCol>
        </PasswordWrapper>
    </ProfileWrapper>
}

export default Profile;