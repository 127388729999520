import {useReducer} from 'react';

const initialState = {
    check:"NOTHING HERE",
    sidebarSelection:"Dashboard",
    heading:"No Heading"
}

const BaseReducer = (state,action) => {
    switch(action.type){
        case "SIDEBAR_SELECTION":{
            return {
                ...state,
                sidebarSelection:action.selection
            }
        }
        case "CHANGE_TOPBAR_HEADING":{
            return {
                ...state,
                heading:action.heading
            }
        }
        case "SET_SIDEBAR_AND_TOPBAR":{
            return {
                ...state,
                heading:action.heading,
                sidebarSelection:action.selection
            }
        }
        default:{

        }
    }
}

export const GetBaseReducer = () => {
    return useReducer(BaseReducer, initialState);
};