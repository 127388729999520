import { FaShoppingCart, FaUser } from "react-icons/fa";
import { MdDashboard } from "react-icons/md";
import { Link } from "react-router-dom";
import {
    DashBoardMenuBox,
    LogoBox, SidebarSection,
    SidebarWrap
} from "./sidebar.style";

const Sidebar = (props) => {
    const { selection } = props;

    const getSelectionClass = (linkName) => {
        if (selection === linkName) {
            return "item-selected";
        } else {
            return "";
        }
    }

    return <SidebarSection>
        <SidebarWrap>
            <LogoBox>
                <img src='/logo.svg' />
            </LogoBox>

            <DashBoardMenuBox>
                <ul>
                    <li>
                        <Link to="/dashboard" className={getSelectionClass("dashboard")}>
                            <MdDashboard />
                            <span>Dashboard</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/merchants" className={getSelectionClass("merchants")}>
                            <FaShoppingCart />
                            <span>Merchants</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admins" className={getSelectionClass("admins")}>
                            <FaUser />
                            <span>Admins</span>
                        </Link>
                    </li>
                </ul>
            </DashBoardMenuBox>

        </SidebarWrap>
    </SidebarSection>
}

export default Sidebar;