import { createGlobalStyle } from 'styled-components';
import { device } from './device';
import { Colors } from '../../components/styles/colors';

const GlobalStyle = createGlobalStyle`
    body{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        height:100vh;
        overflow:hidden;
        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        margin-bottom:20px;
        color:${Colors.headingColor};
    }
    h1{
        font-size:48px;
        line-height:54px;

        @media ${device.laptop} {
            font-size:42px;
            line-height:48px;
        }

        @media ${device.mobileXL} {
            font-size:38px;
            line-height:44px;
        }
        @media ${device.mobileL} {
            font-size:30px;
            line-height:34px;
        }

    }
    h2{
        font-size: 42px;
        line-height:48px;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }
    }
    h3{
        font-size: 36px;
        line-height:42px;
        // text-transform:uppercase;

        @media ${device.mobileXL} {
            font-size: 26px;
            line-height:32px;
        }
    }
    h4{
        font-size:30px;
        line-height:36px;

        @media ${device.tablet} {
            font-size:28px;
            line-height:32px;
        }
    }
    h5{
        font-size:22px;
        line-height:28px;
    }
    h6{
        font-size:18px;
        line-height:24px;

        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }

    p{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color:${Colors.paragraphColor};
        margin-bottom:20px;
        font-size:13px;
        line-height:1.45;
    }
    span{
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color:${Colors.paragraphColor};
        margin-bottom:20px;
        font-size:13px;
        line-height:1.45;
    }
    a{
        font-size:13px;
        font-weight:400;
        color:${Colors.linkTextColor};
        text-decoration:none;
        font-family: 'Inter', sans-serif;
        transition:all .5s;

        span{
            font-family: 'Inter', sans-serif !important;
        }
    }
    a:hover{
        transition:all .5s;
    }
    button{
        font-size:13px;
        font-weight:400;
        font-family: 'Inter', sans-serif;
        background-color: ${Colors.buttonBgColor};
        color: ${Colors.buttonTextColor};
    }
    button:hover{
        background-color:${Colors.buttonHoverBgColor};
    }
    
    img{
        margin-bottom:0px;
        object-fit:cover;
        max-width:100%;
    }
    .m-b-0{
        margin-bottom: 0px !important;
    }
    input{
        border: 1px solid ${Colors.inputBorderColor};
        width:100%;
        padding:5px;
    }
    input,select{
        font-size:13px;
        color:${Colors.inputTextColor};
        background:${Colors.inputBgColor};
        width:100%;
        padding:5px;
        box-sizing: border-box;
    }
    select option{
        color:${Colors.paragraphColor};
    }
    .err-span{
        color:#FF0000;
        display:flex;
        margin-top:-10px;
        font-size:11px;
        line-height:1;
        margin-bottom:0;
    }
    .success-span{
        color:#000000;
        display:flex;
        margin-top:-10px;
        font-size:11px;
        line-height:1;
        margin-bottom:0;
    }
    textarea{
        border: 1px solid ${Colors.inputBorderColor};
        background:${Colors.inputBgColor};
        color:${Colors.inputTextColor};
        border-radius: 5px;
        width:100%;
        height:100px;
    }
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline-color: ${Colors.themeColorPrimary};
        outline-style: solid;
    }
    // ####################### Margin styles ########################//
    .m-b-5{
        margin-bottom:10px;
    }
    .m-b-10{
        margin-bottom:10px;
    }
    .m-b-15{
        margin-bottom:15px;
    }
    .m-b-20{
        margin-bottom:10px;
    }
    .m-b-25{
        margin-bottom:10px;
    }
    .m-b-30{
        margin-bottom:10px;
    }
    .m-b-35{
        margin-bottom:10px;
    }
    .m-b-40{
        margin-bottom:10px;
    }
    .m-b-45{
        margin-bottom:10px;
    }
    .m-b-50{
        margin-bottom:10px;
    }
    .m-b-55{
        margin-bottom:10px;
    }
    .m-b-60{
        margin-bottom:10px;
    }
    .m-b-65{
        margin-bottom:10px;
    }
    .m-b-70{
        margin-bottom:10px;
    }
    .m-b-75{
        margin-bottom:10px;
    }
    .m-b-80{
        margin-bottom:10px;
    }
    .m-b-85{
        margin-bottom:10px;
    }
    .m-b-90{
        margin-bottom:10px;
    }
    .m-b-95{
        margin-bottom:10px;
    }
    .m-b-100{
        margin-bottom:10px;
    }

    .m-t-5{
        margin-top:5px !important;
    }
    .m-t-10{
        margin-top:10px !important;
    }
    .m-t-15{
        margin-top:15px !important;
    }
    .m-t-20{
        margin-top:20px !important;
    }
    .m-t-25{
        margin-top:25px !important;
    }
    .m-t-30{
        margin-top:30px !important;
    }
    .m-t-35{
        margin-top:35px !important;
    }
    .m-t-40{
        margin-top:40px !important;
    }
    .m-t-45{
        margin-top:45px !important;
    }
    .m-t-50{
        margin-top:50px !important;
    }
    .m-t-55{
        margin-top:55px !important;
    }
    .m-t-60{
        margin-top:60px !important;
    }
    .m-t-65{
        margin-top:65px !important;
    }
    .m-t-70{
        margin-top:70px !important;
    }
    .m-t-75{
        margin-top:75px !important;
    }
    .m-t-80{
        margin-top:80px !important;
    }
    .m-t-85{
        margin-top:85px !important;
    }
    .m-t-90{
        margin-top:90px !important;
    }
    .m-t-95{
        margin-top:95px !important;
    }
    .m-t-100{
        margin-top:100px !important;
    }
    
    //######################## TABLE Styles ##########################
    table{
        width:100%;
        border:1px solid ${Colors.tableBorderColor};
        border-radius: 8px;
        border-collapse: unset !important;
        border-spacing: 0;
        cell
    }
    table tr:first-child{
        background:${Colors.tableHeaderColor};
        border-bottom:1px solid ${Colors.tableBorderColor};
    }
    tr th{
        font-weight:normal;
        font-size:13px;
        padding:5px 5px;
        border-bottom:1px solid ${Colors.tableBorderColor};
    }
    tr th div{
        border-right:1px solid ${Colors.tableBorderColor};
        padding:8px 15px;
    }
    tr th:last-child{
        border:none;
        border-bottom:1px solid ${Colors.tableBorderColor};
    }
    tr th:last-child div{
        border:none;
    }
    tr td{
        padding:10px 15px;
        border-bottom:1px solid ${Colors.tableBorderColor};
        background:#fff;
        font-size:13px;
    }
    tr td a{
        text-decoration:none;
        font-size:13px;
        color:${Colors.paragraphColor};
    }
    tr:last-child td{
        border:none;
    }

    .clickable-th{
        cursor:pointer;
        display:flex;
        align-items:center;
        justify-content:space-between;
        width:100%;

        span{
            margin:0;
            line-height:1;
            display:inline-flex;
            font-weight:bold;
        }
        
        svg{
            font-size:14px;
        }
    }

    .flex-center-layout{
        display:flex;
        align-items:center;
        justify-content:center;
    }

   //########################## Modal dialog styles ###################################
   .modal-dialog{
        .modal-title{
            font-size:22px;
        }

        .modal-header .btn-close:hover{
            background-color:transparent;
        }
        
        .modal-body .bottom-btn-layout button{
            padding:6px 20px;
            border:none;
            background-color:${Colors.modalSubmitBtnBgColor};
            color:${Colors.whiteColor};
            border-radius:4px;
            margin:5px;

            &:hover{
                background-color:${Colors.modalSubmitBtnBgHover};
            }
        }
        .modal-body .bottom-btn-layout .modal-cancel-btn{
            background-color:${Colors.modalCancelBtnBgColor};   

            &:hover{
                background-color:${Colors.modalCancelBtnBgHover};
            }
        }
        input{
            border: 1px solid ${Colors.modalInputBorderColor};
            border-radius:4px;
            width:100%;
            padding:8px;
            margin-bottom:15px;
            height:38px;
            box-sizing: border-box;
        }

        textarea{
            font-size:13px;
            border: 1px solid ${Colors.modalInputBorderColor};
            border-radius:4px;
            width:100%;
            padding:8px;
            margin-bottom:15px;
            box-sizing: border-box;
        }

        label{
            font-size:12px;
            line-height:1;
            display:block;
            margin-bottom:5px;
            font-weight:500;
        }

        .react-creatable-select,.react-select{
            border-radius:4px;
            width:100%;
            margin-bottom:15px;

            .rSelect__control{
                border-color:${Colors.modalInputBorderColor};
            }

            .rSelect__control--is-focused{
                box-shadow:0 0 0 1px ${Colors.themeColorPrimary};
            }
    
            .rSelect__control input{
                height:unset;
            }
    
            .rSelect__single-value{
                font-size:13px;
                text-decoration:none;
            }
    
            .rSelect__placeholder{
                font-size:13px;
            }

            .rSelect__menu{
                border-radius:5px;
                border:1px solid ${Colors.dropdownBorderColor};
                box-shadow:${Colors.dropdownBoxShadowColor};
                padding:0;
            }

            .rSelect__menu-list{
                padding:0;
            }

            .rSelect__option{
                font-size:13px;
                color:${Colors.dropdownItemTextColor};

                &:first-child{
                    border-top-left-radius:5px;
                    border-top-right-radius:5px;
                }
                &:last-child{
                    border-bottom-left-radius:5px;
                    border-bottom-right-radius:5px;
                }
            }

            .rSelect__option:hover{
                background:${Colors.dropdownItemBgHover};
            }

            .rSelect__option--is-focused{
                background:${Colors.themeColorPrimaryHover + "22"};
            }

            .rSelect__option--is-selected{
                background:${Colors.themeColorPrimaryHover + "44"};
            }
        }
   }


   //######################## ALERT BOX STYLES ##########################
    .alert-dialog-modal{
        text-align:center;

        .h4{

        }
        .modal-header{
            border:none;
            padding:0;
            position:relative;

            button{
                right:20px;
                top:20px;
                position:absolute;
                z-index:1;
            }
        }

        .modal-body{
            padding-bottom:25px;
        }
    }
    .alert-dialog-modal .modal-body{
        svg{
            font-size:80px;
            color:${Colors.themeColorPrimary + "44"};
        }
        h1,h2,h3,h4,h5,h6{
            margin:0px 0px 10px;
        }
        p{

        }
        button{
            padding:6px 20px;
            border:none;
            background-color:${Colors.alertDialogSubmitButtonColor};
            color:${Colors.whiteColor};
            border-radius:4px;
            margin:5px;

            &:hover{
                background-color:${Colors.alertDialogSubmitButtonColor + "AA"};
            }
        }

        button.cancel-btn{
            padding:6px 20px;
            border:none;
            background-color:${Colors.modalCancelBtnBgHover};
            color:${Colors.blackColor};
            border-radius:4px;
            margin:5px;

            &:hover{
                background-color:${Colors.modalCancelBtnBgHover + "33"};
            }
        }
    }



`;

export default GlobalStyle;
