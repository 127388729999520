import styled from "styled-components";
import {Colors} from "../../components/styles/colors";

export const ProfileWrapper = styled.div`
    padding: 20px;
    border-top:1px solid #ddd;
`;

export const ProfileDetailsCol = styled.div`
    display:flex;
`;

export const ProfilePicLayout = styled.div`
    .change-photo-btn{
        margin-top:10px;
        border:none;
        display: flex;
        background: ${Colors.themeColorPrimary};
        justify-content:center;
        color: ${Colors.whiteColor};
        padding: 5px 20px;
        border-radius: 4px;
        font-size: 13px;
        -moz-box-align: center;
        align-items: center;
        width:100%;
        text-align:center;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
        }
    }
`;

export const ProfilePicCol = styled.div`
    position:relative;
    img{
        width:150px;
        height:150px;
        border-radius:100%;
    }
    &:hover .delete-layout{
        display:flex;
    }
`;

export const ProfileDescLayout = styled.div`
    margin-left:30px;
    margin-top:15px;
    h6{
        margin-bottom:5px;
    }
`;

export const DeleteLayout = styled.div`
    background:${Colors.themeColorPrimary+"99"};
    border-radius:100%;
    position:absolute;
    right:0;
    left:0;
    top:0;
    bottom:0;
    width::100%;
    height:100%;
    align-items:center;
    justify-content:center;
    display:none;
    svg{
        font-size:40px;
        cursor:pointer;
        color:${Colors.whiteColor};
    }
`;

export const PasswordWrapper = styled.div`
    margin-top:50px;
    max-width:300px;

    h5{
        margin-bottom:15px;
    }

    .save-password-btn{
        margin-top:10px;
        border:none;
        display: flex;
        background: ${Colors.themeColorPrimary};
        justify-content:center;
        color: ${Colors.whiteColor};
        padding: 5px 20px;
        border-radius: 4px;
        font-size: 13px;
        -moz-box-align: center;
        align-items: center;
        width:100%;
        text-align:center;

        &:hover{
            background:${Colors.themeColorPrimaryHover};
        }
    }
`;

export const PasswordCol = styled.div`
    
`;
export const PasswordFieldCol = styled.div`
    margin-bottom:15px;

    label{
        font-size:13px;
    }
`;
