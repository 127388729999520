import * as Constants from './url';

export const CHECK_LOGIN = Constants.BASE_API_URL + "superadminlogin";
export const FORGOT_PASSWORD = Constants.BASE_API_URL + "forgotpasswordadmin";
export const CHECK_FORGOT_PASSWORD_CODE = Constants.BASE_API_URL + "checkForgotPasswordCodeadmin";
export const RESET_PASSWORD = Constants.BASE_API_URL + "resetpasswordadmin";

export const CREATE_MERCHANT = Constants.BASE_API_URL + "addmerchant";
export const UPDATE_MERCHANT = Constants.BASE_API_URL + "updatemerchant";
export const UPDATE_MERCHANT_STATUS = Constants.BASE_API_URL + "updatemerchantstatus";
export const GET_MERCHANT = Constants.BASE_API_URL + "getmerchantlist";

export const ADD_MERCHANT_USER = Constants.BASE_API_URL + "addmerchantuserfromsa";
export const UPDATE_MERCHANT_USER = Constants.BASE_API_URL + "updatemerchantuserfromsa";
export const UPDATE_MERCHANT_USER_STATUS = Constants.BASE_API_URL + "updatemerchantuserstatusfromsa";
export const GET_MERCHANT_USERS = Constants.BASE_API_URL + "getmerchantuserlistfromsa";

export const ADD_ADMIN_USER = Constants.BASE_API_URL + "addsuperadmin";
export const UPDATE_ADMIN_USER = Constants.BASE_API_URL + "updatesuperadmin";
export const UPDATE_ADMIN_USER_STATUS = Constants.BASE_API_URL + "changesuperadminstatus";
export const GET_ADMIN_USERS = Constants.BASE_API_URL + "getsuperadminlist";

export const CHANGE_PASSWORD = Constants.BASE_API_URL + "changepasswordsa";
export const GET_DASHBOARD = Constants.BASE_API_URL + "getdashboard";