import React from "react";
import {LoginHeaderWrap, LoginHeaderCol} from './loginheader.style';
import { Link } from "react-router-dom";

const LoginHeader = ()=>{
    return(
        <LoginHeaderWrap>
            <LoginHeaderCol>
                <Link to={"/"}>
                    {/* <img src='../images/logo.svg'/> */}
                </Link>
            </LoginHeaderCol>
        </LoginHeaderWrap>
    )
}
export default LoginHeader;