import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import styled from "styled-components";
import { Colors } from '../styles/colors';

const ResultSizeDropdownWrapper = styled.div`
    margin-right:10px;
    display:flex;
    height:30px;

    span{
        font-size:13px;
        margin-bottom:0;
    }
    .dropdown .dropdown-toggle.btn{
        background:${Colors.dropdownBtnBg};
        border:none;
        padding:3px 10px;
        font-size:13px;
        color:${Colors.dropdownBtnTextColor};
        border:none;
        height:30px;
        border-radius:4px;
        border:1px solid ${Colors.dropdownBtnBorderColor};
    }
    .dropdown .dropdown-toggle.btn.show{
        background:${Colors.dropdownBtnBgHover};
    }
    .dropdown .dropdown-toggle.btn:hover{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .btn-check:checked + .btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active{
        background:${Colors.dropdownBtnBgHover};
        color:${Colors.dropdownBtnTextColor};
    }
    .dropdown-menu.show{
        padding:0;
        min-width:60px;
        border-radius:5px;
        border:1px solid ${Colors.dropdownBorderColor};
        box-shadow:${Colors.dropdownBoxShadowColor};
    }
    .dropdown-item{
        padding:10px 20px;
        background:${Colors.dropdownItemBg};
        color:${Colors.dropdownItemTextColor};

        &:first-child{
            border-top-left-radius:5px;
            border-top-right-radius:5px;
        }
        &:last-child{
            border-bottom-left-radius:5px;
            border-bottom-right-radius:5px;
        }
    }
    .dropdown-item:hover{
        background:${Colors.dropdownItemBgHover} !important;
    }
`;

const ResultSizeDropdown = (props) => {
    const {dropdownLabel,dropdownList,onClickResultSize} = props;

    return (<ResultSizeDropdownWrapper>
        <Dropdown data-bs-theme="dark">
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
                {dropdownLabel}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    dropdownList.map((item) => {
                    return (
                        <Dropdown.Item 
                            as = "button"
                            onClick={()=>{onClickResultSize(item.label)}} 
                            value={item}
                            style={{background:item.label==dropdownLabel?Colors.dropdownItemBgSelected:Colors.whiteColor}}
                        >
                            {item.label}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    </ResultSizeDropdownWrapper>
    )
}

export default ResultSizeDropdown;