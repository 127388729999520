import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';
import { Others } from '../../components/styles/others';

export const LoginSection = styled.section`
    background-image: url('./images/bg-image.jpg');
    background-repeat:no-repeat;
`;

export const LoginWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh - 90px);
`;

export const LoginCol = styled.div`
    max-width:450px;
    min-width:450px;
    margin:auto;
    text-align:center;

    h1{
        font-size:22px;
    }

    input{
        width:100%;
        margin-bottom:20px;
        padding:10px 15px;
        border-radius:0;
        background:#fff;
        border-radius: 4px;
    }

    input[type="email"]{
        
    }

    input[type="password"]{

    }

    button{
        border:none;
        width:100%;
        padding:10px 15px;
        text-align:center;
        justify-content: center;
        color:#fff;
        text-transform:uppercase;
        margin-bottom:15px;
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;

        svg{
            margin-left:10px;
        }
    }

    .ortext{
        margin-bottom:15px;
    }

    .err-span{
        margin-top:-15px;
        margin-bottom:8px;
    }

    a{
        text-decoration:underline;
    }

`;

export const GoogleBtnWrap = styled.div`
    button{
        box-shadow: ${Others.btnBoxShadow};
        color: ${Colors.paragraphColor};
        text-decoration: none;
        padding:10px 20px;
        display:inline-block;
        margin-bottom:15px;
        width:auto;
        background:none;
        border-radius: 4px;
        border:1px solid transparent;

        &:hover{
            box-shadow:none;
            background:none;
            border:1px solid ${Colors.inputBorderColor};
        }
    }
`;

export const ForgotPassColWrap = styled.div`
    display:flex;
    flex-direction:column;
    margin-bottom:10px;

    a{
        display:inline-block;
        margin-bottom:10px;
        text-decoration:underline;
        color:${Colors.paragraphColor};


        &:last-child{
            margin-bottom:0;
        }
    }
`;
