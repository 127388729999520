import { BsArrowUp, BsArrowDown } from "react-icons/bs"

const SortableTableHeader = (props) => {
    const {tableHeader, sort, sortDir, tableSortCol, sortTableLocal} = props;

    const onClickTableHeader = () => {
        sortTableLocal(tableSortCol);
    }

    return <th onClick={onClickTableHeader}>
        <div className="clickable-th">
            <span>{tableHeader}</span>
            {
                sort == tableSortCol &&
                sortDir == "asc" &&
                <BsArrowUp />
            }
            {
                sort == tableSortCol &&
                sortDir == "desc" &&
                <BsArrowDown />
            }
        </div>
    </th>
}


export default SortableTableHeader;