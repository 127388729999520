import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FaArrowLeftLong } from 'react-icons/fa6';
import BasicDropdown from '../../components/dropdowns/basicDropdown';
import Checkbox from '../../components/input/checkbox';
import { FieldGroupWrapper, FormWrapper } from '../../components/styles/addEditFormStyles';
import { BackButton, ListingHeaderLayout, ListingHeaderLeftLayout, ListingHeaderRightLayout, ListingHeaderWrapper } from '../../components/styles/listingHeaderStyles';
import { useAuth } from '../../hooks/useAuth';
import { CREATE_MERCHANT, UPDATE_MERCHANT } from '../../utils/apiEndPoints';
import { STATES, WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from '../../utils/utilFunctions';

const STATE_PLACEHOLDER = "Select State";
const SUBSCRIPTION_PLACEHOLDER = "Select Subscription";

const initialState = {
    //Merchant Details
    merchantName: "",
    merchantCompanyFullName: "",
    merchantEmail: "",
    merchantDomain: "",
    merchantPhone: "",
    subscriptionPlan: "",

    //Merchant Address
    merchantAddress: "",
    merchantCity: "",
    merchantState: "",
    merchantCountry: "",
    merchantPincode: "",

    //Tax Info
    merchantTaxId: "",
    isTaxExclusive: "N",
    merchantTaxPercentage: "",

    //Payment gateway
    razorpayApiKey: "",
    razorpaySecret: "",
    razorpayTestApiKey: "",
    razorpayTestSecret: "",

    // Invoice and Sendgrid
    invoiceStartNumber: "",
    invoicePrefix: "",
    sendgridFromEmail: "",
    sendgridFromName: "",

    //Dev
    enableAddEditPageFields: "Y",
    enableAddEditProductUdfs: "Y",
    enableAddEditApis: "Y",

    errors: {
        merchantName: "",
        merchantEmail: "",
        merchantPhone: "",
        merchantAddress: "",
        merchantCity: "",
        merchantState: "",
        merchantCountry: "",
    },
    apiErr: "",
}

const CreateUpdateTag = (props) => {
    const { merchantComp, onClickSubmit, onClickCancel } = props;

    const [componentState, setComponentState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (merchantComp.showMerchantComponent) {
            if (merchantComp.isEdit) {
                setComponentState(merchantComp.merchantObj);
                inputRef.current.focus();
            } else {
                setComponentState(initialState);
            }
        }
    }, [merchantComp.showMerchantComponent])

    const inputChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.value
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(componentState.merchantName)) {
            hasErrors = true;
            errors.merchantName = "Please enter merchant name"
        }

        if (isEmptyVariable(componentState.merchantCompanyFullName)) {
            hasErrors = true;
            errors.merchantCompanyFullName = "Please enter merchant conpany name"
        }

        if (isEmptyVariable(componentState.merchantEmail)) {
            hasErrors = true;
            errors.merchantEmail = "Please enter merchant email"
        }

        if (isEmptyVariable(componentState.merchantDomain)) {
            hasErrors = true;
            errors.merchantDomain = "Please enter merchant domain"
        }

        if (isEmptyVariable(componentState.merchantPhone)) {
            hasErrors = true;
            errors.merchantPhone = "Please enter merchant phone"
        }

        if (isEmptyVariable(componentState.subscriptionPlan)) {
            hasErrors = true;
            errors.subscriptionPlan = "Please select subscription"
        }

        if (isEmptyVariable(componentState.merchantAddress)) {
            hasErrors = true;
            errors.merchantAddress = "Please enter merchant address"
        }

        if (isEmptyVariable(componentState.merchantCity)) {
            hasErrors = true;
            errors.merchantCity = "Please enter merchant city"
        }

        if (isEmptyVariable(componentState.merchantState)) {
            hasErrors = true;
            errors.merchantState = "Please select merchant state"
        }

        if (isEmptyVariable(componentState.merchantCountry)) {
            hasErrors = true;
            errors.merchantCountry = "Please enter merchant country"
        }

        if (isEmptyVariable(componentState.merchantPincode)) {
            hasErrors = true;
            errors.merchantPincode = "Please enter merchant pincode"
        }

        if (isEmptyVariable(componentState.merchantTaxId)) {
            hasErrors = true;
            errors.merchantTaxId = "Please enter merchant tax Id"
        }

        setComponentState({
            ...componentState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = async () => {
        if (!validateForm()) {
            try {
                if (merchantComp.isEdit) {
                    await updateMerchant();
                } else {
                    await createNewMerchant();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setComponentState({
                    ...componentState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alert.heading)
                })

                setTimeout(() => {
                    setComponentState({
                        ...componentState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            // setTimeout(() => {
            //     setComponentState({
            //         ...componentState,
            //         errors: {}
            //     })
            // }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createNewMerchant = async () => {
        await callAuthApi(CREATE_MERCHANT, {
            merchantName:componentState.merchantName,
            merchantCompanyFullName:componentState.merchantCompanyFullName,
            merchantEmail:componentState.merchantEmail,
            merchantDomain:componentState.merchantDomain,
            merchantPhone:componentState.merchantPhone,
            subscriptionPlan:componentState.subscriptionPlan,

            //Merchant Address
            merchantAddress:componentState.merchantAddress,
            merchantCity:componentState.merchantCity,
            merchantState:componentState.merchantState,
            merchantCountry:componentState.merchantCountry,
            merchantPincode:componentState.merchantPincode,

            //Tax Info
            merchantTaxId:componentState.merchantTaxId,
            isTaxExclusive:componentState.isTaxExclusive,
            merchantTaxPercentage:componentState.merchantTaxPercentage,

            //Payment gateway
            razorpayApiKey:componentState.razorpayApiKey,
            razorpaySecret:componentState.razorpaySecret,
            razorpayTestApiKey:componentState.razorpayTestApiKey,
            razorpayTestSecret:componentState.razorpayTestSecret,

            // Invoice and Sendgrid
            invoiceStartNumber:componentState.invoiceStartNumber,
            invoicePrefix:componentState.invoicePrefix,
            sendgridFromEmail:componentState.sendgridFromEmail,
            sendgridFromName:componentState.sendgridFromName,

            //Dev
            enableAddEditPageFields: componentState.enableAddEditPageFields,
            enableAddEditProductUdfs: componentState.enableAddEditProductUdfs,
            enableAddEditApis: componentState.enableAddEditApis,
        }, user, logout)
    }

    const updateMerchant = async () => {
        await callAuthApi(UPDATE_MERCHANT, {
            merchantId: merchantComp.merchantObj.merchantId,

            merchantName:componentState.merchantName,
            merchantCompanyFullName:componentState.merchantCompanyFullName,
            merchantEmail:componentState.merchantEmail,
            merchantDomain:componentState.merchantDomain,
            merchantPhone:componentState.merchantPhone,
            subscriptionPlan:componentState.subscriptionPlan,

            //Merchant Address
            merchantAddress:componentState.merchantAddress,
            merchantCity:componentState.merchantCity,
            merchantState:componentState.merchantState,
            merchantCountry:componentState.merchantCountry,
            merchantPincode:componentState.merchantPincode,

            //Tax Info
            merchantTaxId:componentState.merchantTaxId,
            isTaxExclusive:componentState.isTaxExclusive,
            merchantTaxPercentage:componentState.merchantTaxPercentage,

            //Payment gateway
            razorpayApiKey:componentState.razorpayApiKey,
            razorpaySecret:componentState.razorpaySecret,
            razorpayTestApiKey:componentState.razorpayTestApiKey,
            razorpayTestSecret:componentState.razorpayTestSecret,

            // Invoice and Sendgrid
            invoiceStartNumber:componentState.invoiceStartNumber,
            invoicePrefix:componentState.invoicePrefix,
            sendgridFromEmail:componentState.sendgridFromEmail,
            sendgridFromName:componentState.sendgridFromName,

            //Dev
            enableAddEditPageFields: componentState.enableAddEditPageFields,
            enableAddEditProductUdfs: componentState.enableAddEditProductUdfs,
            enableAddEditApis: componentState.enableAddEditApis,
        }, user, logout);
    }

    const taxHandleChange = (e) => {
        setComponentState({
            ...componentState,
            isTaxExclusive: e.target.checked ? "N" : "Y"
        })
    }

    const devEnableFlagsChange = (e) => {
        setComponentState({
            ...componentState,
            [e.target.name]: e.target.checked ? "Y" : "N"
        })
    }

    const merchantStateDropdownItemClick = (item) => {
        setComponentState({
            ...componentState,
            merchantState: item.state
        })
    }

    const merchantPlanDropdownItemClick = (item) => {
        setComponentState({
            ...componentState,
            subscriptionPlan: item.plan
        })
    }

    return <>

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={onClickCancel}><FaArrowLeftLong /></BackButton>
                    <h6>{merchantComp.isEdit ? "Edit Merchant" : "Add Merchant"}</h6>
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={onClickSubmitBtn}>Save</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        <FormWrapper>
            <Row>
                {
                    !isEmptyVariable(componentState.apiErr) &&
                    <Col md={12}>
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    </Col>
                }
                <Col md={12}>
                    <FieldGroupWrapper>
                        <h6>Merchant Details</h6>
                        <Row>
                            <Col md="6">
                                <label>Merchant Name*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Merchant Name" : "Enter Merchant Name"}
                                    value={componentState.merchantName}
                                    name="merchantName"
                                    onChange={inputChange}
                                    autoFocus
                                    ref={inputRef}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantName) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantName}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Merchant Company Name*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Merchant Company Name" : "Enter Merchant Company Name"}
                                    value={componentState.merchantCompanyFullName}
                                    name="merchantCompanyFullName"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantCompanyFullName) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantCompanyFullName}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Email*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Email" : "Enter Email"}
                                    value={componentState.merchantEmail}
                                    name="merchantEmail"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantEmail) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantEmail}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Domain Name (https://www.example.com)*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Domain" : "Enter Domain"}
                                    value={componentState.merchantDomain}
                                    name="merchantDomain"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantDomain) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantDomain}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Phone Number*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Phone Number" : "Enter Phone Number"}
                                    value={componentState.merchantPhone}
                                    name="merchantPhone"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantPhone) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantPhone}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Subscription*</label>
                                <BasicDropdown
                                    placeholder={SUBSCRIPTION_PLACEHOLDER}
                                    selectedValue={componentState.subscriptionPlan}
                                    label="plan"
                                    options={[{ plan: "Free" }, { plan: "Starter" }, { plan: "Silver" }, { plan: "Gold" }, { plan: "Custom" }]}
                                    dropdownItemClick={merchantPlanDropdownItemClick}
                                    wrapperStyles={{ marginBottom: 0 }}
                                    minWidth={"100%"}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.subscriptionPlan) &&
                                    <span className="err-span m-t-10 m-b-10">{componentState.errors.subscriptionPlan}</span>
                                }
                            </Col>
                        </Row>

                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Merchant Details</h6>
                        <Row>
                            <label>Address*</label>
                            <Col md="12">
                                <textarea
                                    placeholder={merchantComp.isEdit ? "Update Address" : "Enter Address"}
                                    value={componentState.merchantAddress}
                                    name="merchantAddress"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantAddress) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantAddress}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>City*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update City" : "Enter City"}
                                    value={componentState.merchantCity}
                                    name="merchantCity"
                                    onChange={inputChange}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantCity) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantCity}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>State*</label>
                                <BasicDropdown
                                    placeholder={STATE_PLACEHOLDER}
                                    selectedValue={componentState.merchantState}
                                    label="state"
                                    options={STATES}
                                    dropdownItemClick={merchantStateDropdownItemClick}
                                    wrapperStyles={{ marginBottom: 0 }}
                                    minWidth={"100%"}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantState) &&
                                    <span className="err-span m-t-10 m-b-10">{componentState.errors.merchantState}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Country*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Country" : "Enter Country"}
                                    value={componentState.merchantCountry}
                                    name="merchantCountry"
                                    onChange={inputChange}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantCountry) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantCountry}</span>
                                }
                            </Col>
                            <Col md="6">
                                <label>Pincode*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Pincode" : "Enter Pincode"}
                                    value={componentState.merchantPincode}
                                    name="merchantPincode"
                                    onChange={inputChange}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantPincode) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantPincode}</span>
                                }
                            </Col>
                        </Row>
                    </FieldGroupWrapper>

                    <FieldGroupWrapper>
                        <h6>Tax Information</h6>
                        <Row>
                            <Col md="12">
                                <label className='m-t-15'></label>
                                <Checkbox
                                    checkBoxId={"inclusiveOfTax"}
                                    checkboxLabel={"Inclusive of tax"}
                                    handleChange={taxHandleChange}
                                    name="inclusiveOfTax"
                                    checked={componentState.isTaxExclusive === "N"}
                                />
                                <div className='m-b-15'></div>
                            </Col>

                            <Col md="6">
                                <label>Tax Id(GST)*</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Tax Id" : "Enter Tax Id"}
                                    value={componentState.merchantTaxId}
                                    name="merchantTaxId"
                                    onChange={inputChange}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                                {
                                    !isEmptyVariable(componentState.errors?.merchantTaxId) &&
                                    <span className="err-span m-b-10">{componentState.errors.merchantTaxId}</span>
                                }
                            </Col>

                            <Col md="6">
                                <label>Tax Percentage</label>
                                <input
                                    placeholder={merchantComp.isEdit ? "Update Tax Percentage" : "Enter Tax Percentage"}
                                    value={componentState.merchantTaxPercentage}
                                    name="merchantTaxPercentage"
                                    onChange={inputChange}
                                    onKeyDown={(e) => handleEnterKeyPress(e)}
                                />
                            </Col>
                        </Row>
                    </FieldGroupWrapper>

                    <Row>
                        <Col md="6">
                            <FieldGroupWrapper>
                                <h6>Invoice and Email(Sendgrid)</h6>
                                <Row>
                                    <Col md="12">
                                        <label>Invoice Prefix</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Invoice Prefix" : "Enter Invoice Prefix"}
                                            value={componentState.invoicePrefix}
                                            name="invoicePrefix"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>Invoice Start Number</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Invoice Start Number" : "Enter Invoice Start Number"}
                                            value={componentState.invoiceStartNumber}
                                            name="invoiceStartNumber"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>From Email(Sendgrid)</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update From Email" : "Enter From Email"}
                                            value={componentState.sendgridFromEmail}
                                            name="sendgridFromEmail"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>From Name(Sendgrid)</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update From Name" : "Enter From Name"}
                                            value={componentState.sendgridFromName}
                                            name="sendgridFromName"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                </Row>
                            </FieldGroupWrapper>

                        </Col>
                        <Col md="6">
                            <FieldGroupWrapper>
                                <h6>Payment Gateway</h6>
                                <Row>
                                    <Col md="12">
                                        <label>Razor Pay API Key</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Razor Pay API Key" : "Enter Razor Pay API Key"}
                                            value={componentState.razorpayApiKey}
                                            name="razorpayApiKey"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>Razor Pay Secret</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Razor Pay Secret" : "Enter Razor Pay Secret"}
                                            value={componentState.razorpaySecret}
                                            name="razorpaySecret"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>Razor Pay Test API Key</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Razor Pay Test API Key" : "Enter Razor Pay Test API Key"}
                                            value={componentState.razorpayTestApiKey}
                                            name="razorpayTestApiKey"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                    <Col md="12">
                                        <label>Razor Pay Test Secret</label>
                                        <input
                                            placeholder={merchantComp.isEdit ? "Update Razor Pay Test Secret" : "Enter Razor Pay Test Secret"}
                                            value={componentState.razorpayTestSecret}
                                            name="razorpayTestSecret"
                                            onChange={inputChange}
                                            onKeyDown={(e) => handleEnterKeyPress(e)}
                                        />
                                    </Col>
                                </Row>
                            </FieldGroupWrapper>
                        </Col>
                    </Row>

                    <FieldGroupWrapper>
                        <h6>Developer Only</h6>
                        <Row>
                            <Col md="4">
                                <Checkbox
                                    checkBoxId={"enableAddEditPageFields"}
                                    checkboxLabel={"Enable Page Fields"}
                                    handleChange={devEnableFlagsChange}
                                    name="enableAddEditPageFields"
                                    checked={componentState.enableAddEditPageFields === "Y"}
                                />
                                <div className='m-b-15'></div>
                            </Col>
                            <Col md="4">
                                <Checkbox
                                    checkBoxId={"enableAddEditProductUdfs"}
                                    checkboxLabel={"Enable Product Custom Fields"}
                                    handleChange={devEnableFlagsChange}
                                    name="enableAddEditProductUdfs"
                                    checked={componentState.enableAddEditProductUdfs === "Y"}
                                />
                                <div className='m-b-15'></div>
                            </Col>
                            <Col md="4">
                                <Checkbox
                                    checkBoxId={"enableAddEditApis"}
                                    checkboxLabel={"Enable Custom APIs"}
                                    handleChange={devEnableFlagsChange}
                                    name="enableAddEditApis"
                                    checked={componentState.enableAddEditApis === "Y"}
                                />
                                <div className='m-b-15'></div>
                            </Col>
                        </Row>
                    </FieldGroupWrapper>


                    {
                        !isEmptyVariable(componentState.apiErr) &&
                        <span className="err-span m-b-10">{componentState.apiErr}</span>
                    }
                </Col>
            </Row>
        </FormWrapper>
    </>
}

export default CreateUpdateTag;