import React from "react";
import styled from "styled-components";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { Colors } from "../styles/colors";

const NoRecordsWrapper = styled.div`
    height:calc(100vh - 100px);
    display:flex;
    align-items:center;
    justify-content:Center;
    text-align:center;
`;

const NoRecordsBox = styled.div`
    max-width:400px;
    margin:auto;
    svg{
        font-size:40px;
        color:${Colors.themeColorPrimaryHover+"99"};
    }

    h6{
        margin:10px 0px;
    }
`;

const NoRecords = (props) =>{
    return <NoRecordsWrapper>
            <NoRecordsBox>
                <FaFilterCircleXmark/>
                <h6>{props.noRecordsMsg ? props.noRecordsMsg : "No Records Found"}</h6>
            </NoRecordsBox>
    </NoRecordsWrapper>
}
export default NoRecords;