import { BsFillPlusCircleFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { useEffect } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import FilterDropdown from "../../components/dropdowns/filterDropdown";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import { Colors } from "../../components/styles/colors";
import {
    BackButton,
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { GET_MERCHANT_USERS, UPDATE_MERCHANT_USER_STATUS } from "../../utils/apiEndPoints";
import { ACTIVATE_ACTION, DEACTIVATE_ACTION, EDIT_ACTION, RESULT_SIZE_ARRAY } from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, ifEmptyReturnStr, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import CreateUpdateMerchant from "./createUpdateMerchantUser";
import { GetReducer } from "./reducer";
import StatusSpan from '../../components/randomComponents/statusSpan';

const MerchantUserList = (props) => {

    const {merchantId, hideMerchantUsers} = props;
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [merchantState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "Merchant Users", heading: "Merchant Users" })
        getMerchantUserList();
    }, []);

    useEffect(() => {
        if (merchantState.refreshFlag) {
            getMerchantUserList();
        }

    }, [merchantState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.userId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.userId, "Active");
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showCreateMerchantUserDialog = () => {
        dispatch({
            type: "SHOW_CREATE_TAG_DIALOG"
        });
    }

    const onClickMerchantUserDialogCancel = () => {
        dispatch({
            type: "RESET_MERCHANT_USER_DIALOG"
        });
    }

    const onClickMerchantUserDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_MERCHANT_USER_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_MERCHANT_USER_DIALOG",
                    createMerchantUserDialog: {
                        showDialog: true,
                        isEdit: true,
                        merchantUserObj: {
                            firstName: item.firstName,
                            lastName: item.lastName,
                            phoneNumber: item.phoneNumber,
                            userType: item.userType,
                            email: item.email,
                            userId: item.userId
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Merchant User",
                        message: "Are you sure you want to deactivate " + item.firstName + " "+item.lastName+ "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Merchant",
                        message: "Are you sure you want to activate " + item.tagName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
        }
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (merchantState.search != merchantState.searchApiKey) {
            dispatch({ type: "RELOAD_TAG_LIST" })
        }
    }

    const onClickResultSize = (resultsize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultsize: resultsize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, merchantState.sort, merchantState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getMerchantUserList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            merchantId: merchantId,
            search: merchantState.search,
            sort: merchantState.sort,
            sortDir: merchantState.sortDir,
            resultsize: merchantState.resultsize,
            pageNo: isEmptyVariable(pageNo) ? merchantState.pageNo : pageNo
        }

        if(merchantState.status !== "All"){
            post.status = merchantState.status;
        }

        let response = {};
        try {
            response = await callAuthApi(GET_MERCHANT_USERS, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (userId, status) => {
        try {
            await callAuthApi(UPDATE_MERCHANT_USER_STATUS, {
                userId: userId,
                status: status
            }, user, logout);

            getMerchantUserList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, DEACTIVATE_ACTION];
        } else if (status === "Inactive") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }

    const statusDropdownClick = (item) => {
        dispatch({
            type:"SET_STATUS_FILTER",
            status:item.status
        })
    }
    return <ContentSection>
        {
            merchantState.isPageLoading &&
            <Loader />
        }

        <ListingHeaderWrapper>
            <ListingHeaderLayout>
                <ListingHeaderLeftLayout>
                    <BackButton onClick={hideMerchantUsers}><FaArrowLeftLong /></BackButton>
                    <ResultSizeDropdown
                        dropdownLabel={merchantState.resultsize}
                        dropdownList={RESULT_SIZE_ARRAY}
                        onClickResultSize={onClickResultSize}
                    />

                    <SearchBox>
                        <input type="text" placeholder="Search merchants..."
                            value={merchantState.search}
                            name="search"
                            onChange={onSearchInputChange}
                            onBlur={searchList}
                            onKeyDown={
                                (e) => {
                                    if (e.key === 'Enter') searchList();
                                }
                            }
                        />
                        <button><BsSearch /></button>
                    </SearchBox>

                    <FilterDropdown
                        laceholder={"Select Status"}
                        selectedValue={merchantState.status}
                        label="status"
                        options={[{status:"All"},{status:"Active"},{status:"Inactive"}]}
                        dropdownItemClick = {statusDropdownClick}
                    />
                </ListingHeaderLeftLayout>

                <ListingHeaderRightLayout>
                    <button onClick={showCreateMerchantUserDialog}><BsFillPlusCircleFill />Add Merchant User</button>
                </ListingHeaderRightLayout>
            </ListingHeaderLayout>
        </ListingHeaderWrapper>
        {
            merchantState.firstLoad &&
            <>
                {
                    !isEmptyArray(merchantState.response.result) &&
                    <ContentTableWrap>
                        <table>
                            <tr>
                                <SortableTableHeader
                                    tableHeader={"Name"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.firstName, u.lastName"}
                                    sortTableLocal={sortTableLocal}
                                />

                                <SortableTableHeader
                                    tableHeader={"Email"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.email"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Phone"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.phoneNumber"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"User Type"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.userType"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Created"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.created_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <SortableTableHeader
                                    tableHeader={"Updated"}
                                    sort={merchantState.sort}
                                    sortDir={merchantState.sortDir}
                                    tableSortCol={"u.updated_at"}
                                    sortTableLocal={sortTableLocal}
                                />
                                <th></th>
                            </tr>

                            {
                                merchantState.response.result.map(item => {
                                    return <tr>
                                        <td>
                                            {item.firstName + " " + item.lastName}
                                            <StatusSpan
                                                status={item.status}
                                            />
                                        </td>
                                        <td>{item.email}</td>
                                        <td>{ifEmptyReturnStr(item.phoneNumber,"-")}</td>
                                        <td>{item.userType}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                        <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td>
                                        <td><TableDropdown
                                            actionArr={getActionArr(item.status)}
                                            onClickTableActionItem={onClickTableActionItem}
                                            item={item}
                                        /></td>
                                    </tr>
                                })
                            }
                        </table>
                        <Pagination
                            breakLabel="..."
                            onPageChange={(e) => getMerchantUserList(e.selected + 1)}
                            pageRangeDisplayed={merchantState.resultsize}
                            pageCount={getPageCount(merchantState.response.count, merchantState.resultsize)}
                            renderOnZeroPageCount={null}
                            forcePage={merchantState.pageNo - 1}
                            totalCount = {merchantState.response.count}
                        />
                    </ContentTableWrap>
                }
                {
                    // if result is empty after calling the api
                    isEmptyArray(merchantState.response.result) &&
                    !merchantState.isPageLoading &&
                    !isEmptyVariable(merchantState.searchApiKey) &&
                    <NoResults />
                }

                {
                    // if result is empty after calling the api and search string is not empty
                    isEmptyArray(merchantState.response.result) &&
                    !merchantState.isPageLoading &&
                    isEmptyVariable(merchantState.searchApiKey) &&
                    <NoRecords />
                }
            </>
        }
        <AlertDialog
            alertState={{ ...merchantState.alertDialog }}
            onClickCancel={onClickAlertCancel}
            onClickSubmit={onClickAlertSubmit}
        />

        <CreateUpdateMerchant
            merchantUserDialog={{
                ...merchantState.createMerchantUserDialog,
                merchantId:merchantId,
            }}
            onClickCancel={onClickMerchantUserDialogCancel}
            onClickSubmit={onClickMerchantUserDialogSubmit}
        />
    </ContentSection>
}

export default MerchantUserList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    height:100vh;
    width:100%;
    padding:20px 20px;
`;