import { GetBaseReducer } from "./reducer";
import { Outlet } from "react-router-dom";
import { ProtectedRoute } from "../protectedRoute";
import Sidebar from "../sidebar";
import Topbar from "./topbar";
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from '../styles/global-styles';

export const ContextBaseLayout = () => {
    const [state, baseDispatch] = GetBaseReducer();
    return <ProtectedRoute>
        <GlobalStyle/>
        <div style={{display:"flex"}}>
            <Sidebar selection = {state.sidebarSelection} />
            <div style={{width:"100%"}}>
                <Topbar heading = {state.heading} />
                <div style={{height:"calc(100vh - 60px)",overflow:"auto"}}>
                <Outlet context={{ baseDispatch }} />
                </div>
            </div>
        </div>
    </ProtectedRoute>;
};