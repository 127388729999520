import { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import BasicDropdown from '../../components/dropdowns/basicDialogDropdown';
import { CancelButton, CustomModal, ModalButtonLayout, SubmitButton } from '../../components/styles/addEditDialogFormStyles';
import { useAuth } from '../../hooks/useAuth';
import { ADD_ADMIN_USER, UPDATE_ADMIN_USER } from '../../utils/apiEndPoints';
import { WARNING_MSG_TIMEOUT } from '../../utils/constants';
import { callAuthApi, ifEmptyReturnStr, isEmptyVariable } from '../../utils/utilFunctions';

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    userType: "",
    errors: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        userType: "",
    },
    apiErr: "",
}

const CreateUpdateTag = (props) => {
    const { adminUserDialog, onClickSubmit, onClickCancel } = props;

    const [dialogState, setDialogState] = useState(initialState);
    const { user, logout } = useAuth();
    const inputRef = useRef(null);

    useEffect(() => {
        if (adminUserDialog.showDialog) {
            if (adminUserDialog.isEdit) {
                setDialogState(adminUserDialog.adminUserObj);
                inputRef.current.focus();
            } else {
                setDialogState(initialState);
            }
        }
    }, [adminUserDialog.showDialog])

    const inputChange = (e) => {
        setDialogState({
            ...dialogState,
            [e.target.name]: e.target.value
        })
    }

    const validateForm = () => {
        let hasErrors = false;
        let errors = {};
        if (isEmptyVariable(dialogState.firstName)) {
            hasErrors = true;
            errors.firstName = "Please enter the first name"
        }

        if (isEmptyVariable(dialogState.lastName)) {
            hasErrors = true;
            errors.lastName = "Please enter the last name"
        }

        if (isEmptyVariable(dialogState.email)) {
            hasErrors = true;
            errors.email = "Please enter the email"
        }

        if (isEmptyVariable(dialogState.userType)) {
            hasErrors = true;
            errors.userType = "Please select the user type"
        }

        if(!adminUserDialog.isEdit){
            if (isEmptyVariable(dialogState.password)) {
                hasErrors = true;
                errors.password = "Please enter the password"
            }

            if (isEmptyVariable(dialogState.confirmPassword)) {
                hasErrors = true;
                errors.confirmPassword = "Please confirm the password"
            }

            if (!isEmptyVariable(dialogState.password) && !isEmptyVariable(dialogState.confirmPassword) &&
                dialogState.password !== dialogState.confirmPassword) {
                hasErrors = true;
                errors.confirmPassword = "Password mismatch"
            }
        }

        setDialogState({
            ...dialogState,
            errors: {
                ...errors
            }
        })
        return hasErrors;
    }

    const handleEnterKeyPress = (e) => {
        if (e.key === 'Enter') {
            onClickSubmitBtn();
        }
    }

    const onClickSubmitBtn = async () => {
        if (!validateForm()) {
            try {
                if (adminUserDialog.isEdit) {
                    await updateAdminUser();
                } else {
                    await createNewAdminUser();
                }
                onClickSubmit(true);
            } catch (alertErr) {
                setDialogState({
                    ...dialogState,
                    apiErr: ifEmptyReturnStr(alertErr.message, alert.heading)
                })

                setTimeout(() => {
                    setDialogState({
                        ...dialogState,
                        apiErr: ""
                    })
                }, WARNING_MSG_TIMEOUT);
            }
        } else {
            setTimeout(() => {
                setDialogState({
                    ...dialogState,
                    errors: {}
                })
            }, WARNING_MSG_TIMEOUT);
        }
    }

    //API Calls
    const createNewAdminUser = async () => {
        await callAuthApi(ADD_ADMIN_USER, {
            firstName: dialogState.firstName,
            lastName: dialogState.lastName,
            targetEmail: dialogState.email,
            phoneNumber: dialogState.phoneNumber,
            password: dialogState.password,
            userType: dialogState.userType,
        }, user, logout)
    }

    const updateAdminUser = async () => {
        await callAuthApi(UPDATE_ADMIN_USER, {
            userId: adminUserDialog.adminUserObj.userId,
            firstName: dialogState.firstName,
            lastName: dialogState.lastName,
            targetEmail: dialogState.email,
            phoneNumber: dialogState.phoneNumber,
            password: dialogState.password,
            userType: dialogState.userType,
        }, user, logout);
    }

    const userTypeDropdownItemClick = (option) => {
        setDialogState({
            ...dialogState,
            userType: option.userType
        })
    }
    return <>
        <CustomModal show={adminUserDialog.showDialog} onHide={onClickCancel}>
            <Modal.Header closeButton>
                <Modal.Title>{adminUserDialog.isEdit ? "Update Admin" : "Create Admin"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md="6">
                        <label>{adminUserDialog.isEdit ? "Update first Name" : "Enter first Name"} *</label>
                        <input
                            value={dialogState.firstName}
                            name="firstName"
                            onChange={inputChange}
                            autoFocus
                            ref={inputRef}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.firstName) &&
                            <span className="err-span m-b-10">{dialogState.errors.firstName}</span>
                        }
                    </Col>
                    <Col md="6">
                        <label>{adminUserDialog.isEdit ? "Update last name" : "Enter last name"} *</label>
                        <input
                            value={dialogState.lastName}
                            name="lastName"
                            onChange={inputChange}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.lastName) &&
                            <span className="err-span m-b-10">{dialogState.errors.lastName}</span>
                        }
                    </Col>
                </Row>

                <label>{adminUserDialog.isEdit ? "Update Email" : "Enter Email"} *</label>
                <input
                    value={dialogState.email}
                    name="email"
                    onChange={inputChange}
                    onKeyDown={(e) => handleEnterKeyPress(e)}
                />
                {
                    !isEmptyVariable(dialogState.errors?.email) &&
                    <span className="err-span m-b-10">{dialogState.errors.email}</span>
                }
                <Row>
                    <Col md="6">
                        <label>{adminUserDialog.isEdit ? "Update Phone Number" : "Enter Phone Number"}</label>
                        <input
                            value={dialogState.phoneNumber}
                            name="phoneNumber"
                            onChange={inputChange}
                            onKeyDown={(e) => handleEnterKeyPress(e)}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.phoneNumber) &&
                            <span className="err-span m-b-10">{dialogState.errors.phoneNumber}</span>
                        }
                    </Col>
                    <Col md="6">
                        <label>Select User Type *</label>
                        <BasicDropdown
                            selectedValue={dialogState.userType}
                            label="userType"
                            options={[{userType:"Super Admin"}]}
                            dropdownItemClick = {userTypeDropdownItemClick}
                            wrapperStyles = {{marginBottom:15}}
                        />
                        {
                            !isEmptyVariable(dialogState.errors?.userType) &&
                            <span className="err-span m-b-10">{dialogState.errors.userType}</span>
                        }
                    </Col>
                </Row>
                {
                    !adminUserDialog.isEdit &&
                    <Row>
                        <Col md="6">
                            <label>Enter Password *</label>
                            <input
                                value={dialogState.password}
                                name="password"
                                onChange={inputChange}
                                type="password"
                            />
                            {
                                !isEmptyVariable(dialogState.errors?.password) &&
                                <span className="err-span m-b-10">{dialogState.errors.password}</span>
                            }
                        </Col>
                        <Col md="6">
                            <label>Confirm Password *</label>
                            <input
                                value={dialogState.confirmPassword}
                                name="confirmPassword"
                                onChange={inputChange}
                                type="password"
                            />
                            {
                                !isEmptyVariable(dialogState.errors?.confirmPassword) &&
                                <span className="err-span m-b-10">{dialogState.errors.confirmPassword}</span>
                            }
                        </Col>
                    </Row>
                }
                {
                    !isEmptyVariable(dialogState.apiErr) &&
                    <span className="err-span m-b-10">{dialogState.apiErr}</span>
                }
                <ModalButtonLayout>
                    <CancelButton onClick={() => onClickCancel()}>Cancel</CancelButton>
                    <SubmitButton onClick={onClickSubmitBtn}>Submit</SubmitButton>
                </ModalButtonLayout>
            </Modal.Body>
        </CustomModal>

    </>
}

export default CreateUpdateTag;