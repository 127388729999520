import {useReducer} from 'react';

const initialState = {
    oldPassword:"",
    newPassword:"",
    confirmNewPassword:"",
    errors:{},
    isLoading:false,
}

const reducer = (state,action) => {
    switch(action.type){
        case "SET_FIELDVALUE" : {
            return {
                ...state,
                [action.fieldName]:action.fieldValue
            }
        }
        case "SET_LOGIN_LOADER_FLAG" : {
            return {
                ...state,
                isLoading:action.flag
            }
        }
        case "SET_VALIDATION_ERRORS" : {
            return {
                ...state,
                errors:{...action.errors}
            }
        }
        case "RESET_CHANGE_PASSWORD_FIELDS" : {
            return {
                ...state,
                oldPassword:"",
                newPassword:"",
                confirmNewPassword:"",
                errors:{},
                isLoading:false,
                apiResponse:action.response,
            }
        }
        default:{

        }
    }
}

export const GetReducer = () => {
    return useReducer(reducer, initialState);
};