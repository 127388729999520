import ReactPaginate from 'react-paginate';
import { PaginiationWrapper, PaginiationSection } from './pagination.style';
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";

const Pagination = (props) => {
    return <PaginiationWrapper>
        <PaginiationSection>
            <ReactPaginate
                breakLabel={props.breakLabel}
                nextLabel={props.nextLabel ? props.nextLabel : <MdNavigateNext />}
                onPageChange={props.onPageChange}
                pageRangeDisplayed={props.pageRangeDisplayed}
                pageCount={props.pageCount}
                previousLabel={props.previousLabel ? props.previousLabel : <MdNavigateBefore />}
                renderOnZeroPageCount={null}
                containerClassName={props.containerClassName ? props.containerClassName : ""}
                forcePage={props.forcePage}
            />
            <p>Total Count: {props.totalCount}</p>
        </PaginiationSection>
    </PaginiationWrapper>
}

export default Pagination;