import styled from 'styled-components';

export const ForgotPassSection = styled.section`
    /* background-image: url('./images/bg-image.jpg'); */
    background-repeat:no-repeat;
    background-size:cover;
`;

export const ForgotPassWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    height:calc(100vh - 90px);
`;

export const ForgotPassCol = styled.div`
    max-width:450px;
    min-width:450px;
    margin:auto;
    text-align:center;

    h1{
        font-size:22px;
    }

    input{
        width:100%;
        margin-bottom:15px;
        padding:10px 15px;
        border-radius:0;
        border-radius: 4px;
    }

    button{
        border:none;
        width:100%;
        padding:10px 15px;
        text-align:center;
        justify-content: center;
        color:#fff;
        text-transform:uppercase;
        margin-bottom:15px;
        border-radius: 4px;
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .err-span{
        margin-bottom:8px;
    }
`;