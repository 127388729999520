import { useEffect } from "react";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import AlertDialog from "../../components/dialogs/alertDialog";
import ResultSizeDropdown from "../../components/dropdowns/resultSizeDropdown";
import TableDropdown from "../../components/dropdowns/tableDropdown";
import Pagination from "../../components/pagination";
import Loader from "../../components/randomComponents/loader";
import NoRecords from "../../components/randomComponents/noRecords";
import NoResults from "../../components/randomComponents/noResults";
import SortableTableHeader from "../../components/randomComponents/sortableTableHeader";
import StatusSpan from "../../components/randomComponents/statusSpan";
import { Colors } from "../../components/styles/colors";
import {
    ListingHeaderLayout,
    ListingHeaderLeftLayout, ListingHeaderRightLayout,
    ListingHeaderWrapper,
    SearchBox
} from '../../components/styles/listingHeaderStyles';
import { useAuth } from "../../hooks/useAuth";
import { GET_MERCHANT, UPDATE_MERCHANT_STATUS } from "../../utils/apiEndPoints";
import { ACTIVATE_ACTION, DEACTIVATE_ACTION, EDIT_ACTION, RESULT_SIZE_ARRAY, SHOW_USERS_ACTION, SUSPEND_ACTION } from "../../utils/constants";
import {
    callAuthApi, getLocalDateOnlyFromUTC, getPageCount, isEmptyArray,
    isEmptyVariable, sortTable
} from "../../utils/utilFunctions";
import MerchantUsersComponent from "../merchantUsers";
import CreateUpdateMerchant from "./createUpdateMerchant";
import { GetReducer } from "./reducer";
const MerchantList = () => {
    const { baseDispatch } = useOutletContext();
    const { user, logout } = useAuth();
    const [merchantState, dispatch] = GetReducer();

    useEffect(() => {
        baseDispatch({ type: "SET_SIDEBAR_AND_TOPBAR", selection: "merchants", heading: "Merchants" })
        getTagsList();
    }, []);

    useEffect(() => {
        if (merchantState.refreshFlag) {
            getTagsList();
        }

    }, [merchantState.refreshFlag]);

    const onClickAlertCancel = () => {
        dispatch({
            type: "RESET_ALERT",
        });
    }

    const onClickAlertSubmit = async (operation, selectedItem) => {
        switch (operation) {
            case "Deactivate":
                await updateStatus(selectedItem.merchantId, "Inactive");
                break;
            case "Activate":
                await updateStatus(selectedItem.merchantId, "Active");
                break;
            case "Suspend":
                await updateStatus(selectedItem.merchantId, "Billing Suspension");
                break;
            default:
                dispatch({
                    type: "RESET_ALERT",
                });
        }
    }

    const showcreateMerchantComp = () => {
        dispatch({
            type: "SHOW_CREATE_TAG_DIALOG"
        });
    }

    const onClickTagDialogCancel = () => {
        dispatch({
            type: "RESET_TAG_DIALOG"
        });
    }

    const onClickTagDialogSubmit = (refreshFlag) => {
        dispatch({
            type: "RESET_TAG_DIALOG",
            refreshFlag: refreshFlag
        });
    }

    //Table Dropdown
    const onClickTableActionItem = (label, item) => {
        switch (label) {
            case "Edit":
                dispatch({
                    type: "SET_TAG_DIALOG",
                    tagDialogState: {
                        showMerchantComponent: true,
                        isEdit: true,
                        merchantObj: {
                            ...item
                        }
                    }
                });
                break;
            case "Deactivate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Deactivate Merchant",
                        message: "Are you sure you want to deactivate " + item.merchantName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Deactivate",
                        operation: "Deactivate",
                        selectedItem: item,
                    }
                });
                break;
            case "Suspend":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Suspend Merchant",
                        message: "Are you sure you want to suspend " + item.merchantName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Suspend",
                        operation: "Suspend",
                        selectedItem: item,
                    }
                });
                break;
            case "Activate":
                dispatch({
                    type: "SET_ALERT",
                    alertState: {
                        showAlertDialog: true,
                        heading: "Activate Merchant",
                        message: "Are you sure you want to activate " + item.merchantName + "?",
                        type: "ALERT",
                        cancelBtn: true,
                        okayBtn: true,
                        cancelBtnLabel: "Cancel",
                        okayBtnLabel: "Activate",
                        operation: "Activate",
                        selectedItem: item,
                    }
                });
                break;
            case "Users":
                dispatch({
                    type: "SHOW_MERCHANT_USERS",
                    merchantId: item.merchantId,
                });
                break;
        }
    }

    const hideMerchantUsers = () => {
        dispatch({
            type: "HIDE_MERCHANT_USERS",
        });
    }

    const onSearchInputChange = (e) => {
        dispatch({
            type: "SEARCH_INPUT_CHANGE",
            value: e.target.value
        })
    }

    const searchList = (e) => {
        if (merchantState.search != merchantState.searchApiKey) {
            dispatch({ type: "RELOAD_TAG_LIST" })
        }
    }

    const onClickResultSize = (resultsize) => {
        dispatch({
            type: "CHANGE_RESULT_SIZE",
            resultsize: resultsize
        });
    }

    const sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn, merchantState.sort, merchantState.sortDir);

        dispatch({
            type: "SET_SORT_AND_DIR",
            sort: sortObj.sortTemp,
            sortDir: sortObj.sortDirTemp,
        })
    }

    //API Calls
    const getTagsList = async (pageNo) => {
        dispatch({ type: "SHOW_PAGE_LOADER" });

        let post = {
            search: merchantState.search,
            sort: merchantState.sort,
            sortDir: merchantState.sortDir,
            resultsize: merchantState.resultsize,
            pageNo: isEmptyVariable(pageNo) ? merchantState.pageNo : pageNo
        }

        let response = {};
        try {
            response = await callAuthApi(GET_MERCHANT, post, user, logout);
            if (isEmptyVariable(pageNo)) {
                dispatch({
                    type: "SET_RESPONSE",
                    response: response.data
                })
            } else {
                dispatch({
                    type: "SET_RESPONSE_AND_PAGE",
                    response: response.data,
                    pageNo: pageNo
                })
            }
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const updateStatus = async (merchantId, status) => {
        try {
            await callAuthApi(UPDATE_MERCHANT_STATUS, {
                merchantId: merchantId,
                merchantStatus: status
            }, user, logout);

            getTagsList();
        } catch (errAlert) {
            dispatch({
                type: "SET_ALERT",
                alertState: errAlert
            });
        }
    }

    const getActionArr = (status) => {
        if (status === "Active") {
            return [EDIT_ACTION, SHOW_USERS_ACTION, DEACTIVATE_ACTION, SUSPEND_ACTION];
        } else if (status === "Inactive" || status === "Billing Suspension") {
            return [EDIT_ACTION, ACTIVATE_ACTION];
        } else {
            return [];
        }
    }

    return <>
        {
            !merchantState.merchantUsers?.showMerchantUsers &&
            <ContentSection>
                {
                    merchantState.isPageLoading &&
                    <Loader />
                }
                {
                    !merchantState.createMerchantComp?.showMerchantComponent &&
                    <>
                        <ListingHeaderWrapper>
                            <ListingHeaderLayout>
                                <ListingHeaderLeftLayout>
                                    <ResultSizeDropdown
                                        dropdownLabel={merchantState.resultsize}
                                        dropdownList={RESULT_SIZE_ARRAY}
                                        onClickResultSize={onClickResultSize}
                                    />

                                    <SearchBox>
                                        <input type="text" placeholder="Search merchants..."
                                            value={merchantState.search}
                                            name="search"
                                            onChange={onSearchInputChange}
                                            onBlur={searchList}
                                            onKeyDown={
                                                (e) => {
                                                    if (e.key === 'Enter') searchList();
                                                }
                                            }
                                        />
                                        <button><BsSearch /></button>
                                    </SearchBox>
                                </ListingHeaderLeftLayout>

                                <ListingHeaderRightLayout>
                                    <button onClick={showcreateMerchantComp}><BsFillPlusCircleFill />Create Merchant</button>
                                </ListingHeaderRightLayout>
                            </ListingHeaderLayout>
                        </ListingHeaderWrapper>
                        {
                            merchantState.firstLoad &&
                            <>
                                {
                                    !isEmptyArray(merchantState.response.result) &&
                                    <ContentTableWrap>
                                        <table>
                                            <tr>
                                                <SortableTableHeader
                                                    tableHeader={"Name"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"m.merchantName"}
                                                    sortTableLocal={sortTableLocal}
                                                />

                                                <SortableTableHeader
                                                    tableHeader={"Email"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"m.merchantEmail"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                <SortableTableHeader
                                                    tableHeader={"Products"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"mpcv.productCount"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                <SortableTableHeader
                                                    tableHeader={"Users"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"mucv.merchantUserCount"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                <SortableTableHeader
                                                    tableHeader={"Customers"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"mccv.merchantCustomerCount"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                <SortableTableHeader
                                                    tableHeader={"Orders"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"merchantOrderCount"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                <SortableTableHeader
                                                    tableHeader={"Created"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"m.created_at"}
                                                    sortTableLocal={sortTableLocal}
                                                />
                                                {/* <SortableTableHeader
                                                    tableHeader={"Updated"}
                                                    sort={merchantState.sort}
                                                    sortDir={merchantState.sortDir}
                                                    tableSortCol={"m.updated_at"}
                                                    sortTableLocal={sortTableLocal}
                                                /> */}
                                                <th></th>
                                            </tr>

                                            {
                                                merchantState.response.result.map(item => {
                                                    return <tr>
                                                        <td>
                                                            {item.merchantName}
                                                            <StatusSpan
                                                                status={item.merchantStatus}
                                                            />
                                                        </td>
                                                        <td>{item.merchantEmail}</td>
                                                        <td>{item.productCount}</td>
                                                        <td>{item.merchantUserCount}</td>
                                                        <td>{item.merchantCustomerCount}</td>
                                                        <td>{item.merchantOrderCount}</td>
                                                        <td>{getLocalDateOnlyFromUTC(item.created_at)}</td>
                                                        {/* <td>{getLocalDateOnlyFromUTC(item.updated_at)}</td> */}
                                                        <td><TableDropdown
                                                            actionArr={getActionArr(item.merchantStatus)}
                                                            onClickTableActionItem={onClickTableActionItem}
                                                            item={item}
                                                        /></td>
                                                    </tr>
                                                })
                                            }
                                        </table>
                                        <Pagination
                                            breakLabel="..."
                                            onPageChange={(e) => getTagsList(e.selected + 1)}
                                            pageRangeDisplayed={merchantState.resultsize}
                                            pageCount={getPageCount(merchantState.response.count, merchantState.resultsize)}
                                            renderOnZeroPageCount={null}
                                            forcePage={merchantState.pageNo - 1}
                                        />
                                    </ContentTableWrap>
                                }
                                {
                                    // if result is empty after calling the api
                                    isEmptyArray(merchantState.response.result) &&
                                    !merchantState.isPageLoading &&
                                    !isEmptyVariable(merchantState.searchApiKey) &&
                                    <NoResults />
                                }

                                {
                                    // if result is empty after calling the api and search string is not empty
                                    isEmptyArray(merchantState.response.result) &&
                                    !merchantState.isPageLoading &&
                                    isEmptyVariable(merchantState.searchApiKey) &&
                                    <NoRecords />
                                }
                            </>
                        }
                    </>
                }
                {
                    merchantState.createMerchantComp?.showMerchantComponent &&
                    <CreateUpdateMerchant
                        merchantComp={{
                            ...merchantState.createMerchantComp,
                        }}
                        onClickCancel={onClickTagDialogCancel}
                        onClickSubmit={onClickTagDialogSubmit}
                    />
                }

                <AlertDialog
                    alertState={{ ...merchantState.alertDialog }}
                    onClickCancel={onClickAlertCancel}
                    onClickSubmit={onClickAlertSubmit}
                />
            </ContentSection>
        }

        {
            merchantState.merchantUsers?.showMerchantUsers &&
            <MerchantUsersComponent
                merchantId={merchantState.merchantUsers.merchantId}
                hideMerchantUsers={hideMerchantUsers}
            />
        }
    </>
}

export default MerchantList;


//STYLES
export const ContentSection = styled.div`
    background:${Colors.MainContentBgColor};
`;

export const ContentTableWrap = styled.div`
    height:100vh;
    width:100%;
    padding:20px 20px;
`;