import styled from 'styled-components';
import { Colors } from '../../components/styles/colors';
import { Modal } from 'react-bootstrap';

export const CustomModal = styled(Modal)`
    .modal-title{
        font-size:22px;
    }
    .modal-header{
        padding:10px 20px;
    }
    .modal-header .btn-close:hover{
        background-color:transparent;
    }
    .modal-body{
        input{
            border: 1px solid ${Colors.modalInputBorderColor};
            border-radius:4px;
            width:100%;
            padding:8px;
            margin-bottom:15px;
            height:34px;
            box-sizing: border-box;
        }

        textarea{
            font-size: 13px;
            border: 1px solid ${Colors.modalInputBorderColor};
            border-radius:4px;
            width:100%;
            padding:8px;
            margin-bottom:15px;
            box-sizing: border-box;
        }

        label{
            font-size:12px;
            line-height:1;
            display:block;
            margin-bottom:5px;
            font-weight:500;
        }

        .input-disabled{
            background: #f5f5f5;
        }
    }
`;
export const CustomModalListing = styled(Modal)`
    .modal-title{
        font-size:22px;
    }
    .modal-header{
        padding:10px 20px;
    }
    .modal-header .btn-close:hover{
        background-color:transparent;
    }
    .modal-body{
    }
`;
export const ModalButtonLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
`;
export const ModalButtonIconLayout = styled.button`
    background:transparent;
    color: ${Colors.buttonIconColor};
    padding: 7px;
    border-radius: 4px;
    margin: ${props=>props.margin?props.margin:"0"};

    &:hover{
        background: ${Colors.buttonIconHoverBgColor};
    }

    svg{
        font-size: 20px;
    }
`;
export const ModalButtonSecondary = styled.button`
    padding: 3px 15px;
    font-size: 12px;
    margin:0;
    border-radius:4px;
    background:${Colors.themeColorPrimary};
    color:${Colors.whiteColor};
    border:none;
`;
export const SubmitButton = styled.button`
    padding:6px 20px;
    border:none;
    background-color:${Colors.modalSubmitBtnBgColor};
    color:${Colors.whiteColor};
    border-radius:4px;
    margin:5px;

    &:hover{
        background-color:${Colors.modalSubmitBtnBgHover};
    }
`;
export const CancelButton = styled(SubmitButton)`
    background-color:${Colors.modalCancelBtnBgColor};   

    &:hover{
        background-color:${Colors.modalCancelBtnBgHover};
    }
`;