import { FaRegTrashCan } from "react-icons/fa6";
import { FaRegEdit } from "react-icons/fa";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import { BiCopyAlt } from "react-icons/bi";

export const WARNING_MSG_TIMEOUT = 5000;
export const DEFAULT_RESULT_SIZE = 10;
export const ALLOWED_FILE_SIZE_IN_MB = 5;
export const CATEGORY_OTHERS_ID = 100;
export const NO_RECORDS_WARNING = "No records found";
export const EMPTY_SEARCH_WARNING = "There aren't any results for that query.";

export const RESULT_SIZE_ARRAY = [
    {
        label: "10",
        isSelected: true
    },
    {
        label: "20",
        isSelected: false
    },
    {
        label: "50",
        isSelected: false
    },
];

export const INITIAL_ALERT_STATE = {
    showAlertDialog: false,
    heading: "",
    message: "",
    type: "ALERT",
    cancelBtn: false,
    okayBtn: false,
    cancelBtnLabel: "Cancel",
    okayBtnLabel: "Okay",
    operation: "",
    selectedItem: {},

}

export const EDIT_ACTION = {
    label: "Edit",
    icon: <FaRegEdit />
}
export const SHOW_USERS_ACTION = {
    label: "Users",
    icon: <FaRegEdit />
}
export const RENAME_ACTION = {
    label: "Rename",
    icon: <FaRegEdit />
}
export const APPLY_ACTION = {
    label: "Apply",
    icon: <IoCheckmarkCircleOutline />
}
export const DELETE_ACTION = {
    label: "Delete",
    icon: <FaRegTrashCan />
}
export const DUPLICATE_ACTION = {
    label: "Duplicate",
    icon: <BiCopyAlt />
}
export const DEACTIVATE_ACTION = {
    label: "Deactivate",
    icon: <BiCopyAlt />
}
export const SUSPEND_ACTION = {
    label: "Suspend",
    icon: <BiCopyAlt />
}
export const ACTIVATE_ACTION = {
    label: "Activate",
    icon: <BiCopyAlt />
}

export const STATES = [
    { state: "Andhra Pradesh" },
    { state: "Arunachal Pradesh" },
    { state: "Assam" },
    { state: "Bihar" },
    { state: "Chhattisgarh" },
    { state: "Goa" },
    { state: "Gujarat" },
    { state: "Haryana" },
    { state: "Himachal Pradesh" },
    { state: "Jharkhand" },
    { state: "Karnataka" },
    { state: "Kerala" },
    { state: "Madhya Pradesh" },
    { state: "Maharashtra" },
    { state: "Manipur" },
    { state: "Meghalaya" },
    { state: "Mizoram" },
    { state: "Nagaland" },
    { state: "Odisha" },
    { state: "Punjab" },
    { state: "Rajasthan" },
    { state: "Sikkim" },
    { state: "Tamil Nadu" },
    { state: "Telangana" },
    { state: "Tripura" },
    { state: "Uttarakhand" },
    { state: "Uttar Pradesh" },
    { state: "West Bengal" }
]