import styled from "styled-components";
import { Colors } from "../styles/colors";
import { ifEmptyReturnStr } from "../../utils/utilFunctions";

const StatusSpanWrapper = styled.div`
    span{
        padding:2px 7px;
        display:inline-block;
        border-radius:4px;
        margin-top:2px;
        font-size:11px;
        margin-bottom:0;
    }
    .activeSpan{
        background:${Colors.statusSpanActiveBackgroundColor};
        color:${Colors.statusSpanActiveTextColor};
    }
    .inactiveSpan{
        background:${Colors.statusSpanInActiveBackgroundColor};
        color:${Colors.statusSpanInActiveTextColor};
    }
    .defaultSpan{
        background:${Colors.statusSpanDefaultBackgroundColor};
        color:${Colors.statusSpanDefaultTextColor};
    }
`;

const StatusSpan = ({ status, displayStatus }) => {
    return <StatusSpanWrapper>
        <span className={status == "Active" ? "activeSpan" : status == "Inactive" ? "inactiveSpan" : "defaultSpan"}>
            {ifEmptyReturnStr(displayStatus, status)}
        </span>
    </StatusSpanWrapper>
}
export default StatusSpan;