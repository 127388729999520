import React from 'react';
import styled from 'styled-components';
import {User} from '@styled-icons/boxicons-regular/User';
import ProfileDropdown from '../../dropdowns/profileDropdown';
import { IoLogOut } from 'react-icons/io5';

const TopbarWrapper = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:7.5px 20px;
    height:57.5px;
    
    h5{
        margin:0;
        line-height:1;
    }

    .dropdown-menu.show {
        padding: 0;
        min-width: 130px;
    }
`;

const Topbar = (props) => {
    const { heading } = props;
    return <TopbarWrapper>
        <h5>{heading}</h5>
        <ProfileDropdown
            dropdownLabel="Nagaraj"
            dropdownList={
                [
                    {
                        option: "Profile",
                        optionUrl: "/profile",
                        optionIcon: <User />
                    },
                    {
                        option: "Logout",
                        optionUrl: "/logout",
                        optionIcon: <IoLogOut />
                    }
                ]
            }
        />
    </TopbarWrapper>
}

export default Topbar;